<template>
  <div class="page-container accounts-page">
    <portal to="page-name">Contas dos pacientes</portal>
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="card card-page">
        <div class="card-header">
          <h1 class="card-title">Contas dos pacientes</h1>
          <div class="columns mt-2">
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <label class="form-label">Convênio</label>
              <select id="filter-insurance" class="form-select"
                      v-model="filters.insurancePlanId">
                <option value="">Todos os convênios</option>
                <option v-for="(item, i) in insurances"
                        :value="item.id" :key="i">{{ item.name }}</option>
              </select>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <label class="form-label">Status</label>
              <select id="filter-status" class="form-select"
                      v-model="filters.status">
                <option value="">Todos os status</option>
                <option v-for="(text, value) in status.statuses"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
            <div class="column form-group">
              <label class="form-label">Pesquisar</label>
              <div class="input-group">
                <input type="text" id="filter-search" class="form-input"
                       v-model="filters.search"
                       placeholder="Código da conta, nome ou prontuário do paciente...">
                <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                        tabindex="-1">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
              </div>
            </div>
            <div class="column col-auto mb-2 col-sm-12 form-group"
                 style="display: flex; align-items: flex-end">
              <button class="btn btn-primary btn-icon btn-icon-left btn-block"
                      @click="open">
                <fa-icon :icon="['fal', 'plus']"/>Nova Conta
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="empty" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'file-user']" size="3x"></fa-icon>
            </div>
            <p class="empty-title h5">Contas dos pacientes</p>
            <p class="empty-subtitle">
              Nenhuma conta encontrada. Verifique os filtros e tente novamente
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th style="width: 50px;">#</th>
              <th class="hide-sm" style="width: 150px;">Data abertura</th>
              <th class="hide-sm" style="width: 150px;">Status</th>
              <th>Nome do paciente</th>
              <th>Código</th>
              <th class="hide-sm text-center">Despesas</th>
              <th class="hide-sm text-right">Total</th>
              <th style="width: 50px;"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="c-hand" v-for="(item, i) in data.items" :key="i"
                @click="edit(item.id)">
              <td>{{ i + 1 }}</td>
              <td class="hide-sm">{{ item.startDate | date('datetime') }}</td>
              <td class="hide-sm">{{ status.getName(item.status) }}</td>
              <td class="text-bold text-primary">{{ item.patient.name }}</td>
              <td class="hide-sm">{{ item.code }}</td>
              <td class="hide-sm text-center">{{ item.items.length }}</td>
              <td class="hide-sm text-right">{{ item.total | currency }}</td>
              <td class="text-right">
                <button class="btn btn-primary btn-icon btn-action btn-sm">
                  <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
        </div>
      </div>
      <account-modal
        v-if="accountModal.show"
        :show="accountModal.show"
        :id="accountModal.id"
      />
    </template>
  </div>
</template>

<script>
import * as status from 'src/data/patient-account-statuses';
import formMixin from '../../../mixins/form';
import accountModal from './Modal.vue';

export default {
  mixins: [formMixin],
  components: {
    accountModal,
  },
  data() {
    return {
      accountModal: {
        id: '',
        show: false,
      },
      accountForm: true,
      path: '/patient-accounts',
      loading: false,
      status,
      debounceTimeout: null,
      insurances: [],
      filters: {
        insurancePlanId: '',
        status: '',
        search: '',
      },
      data: {
        items: [],
        total: 0,
        hasMore: false,
      },
    };
  },
  mounted() {
    this.loadInsurances();
    this.load();
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.load, 600);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        limit: 0,
      };

      if (this.filters.insurancePlanId) {
        params.insurancePlanId = this.filters.insurancePlanId;
      }

      if (this.filters.status) {
        params.status = this.filters.status;
      }

      if (this.filters.search) {
        params.search = this.filters.search;
      }

      return this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurances', { params })
        .then(({ data }) => {
          this.insurances = data.items.map(item => ({
            id: item.plan.id,
            name: item.customName,
          }));
        })
        .catch(() => {});
    },
    open() {
      this.accountModal.id = '61cccf3c1066852263507d77';
      this.accountModal.show = true;
    },
    edit(id) {
      this.$router.push(`/financial/accounts/${id}/edit`);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";
.accounts-page {}
</style>
