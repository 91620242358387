<template>
  <div class="page-container billing-invoices-page">
    <portal to="page-name">Faturas</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Faturas</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-5 col-md-12 col-sm-12 form-group">
            <label class="form-label">Convênio</label>
            <select id="filter-covenant" class="form-select"
                    v-model="filter.insurancePlanId">
              <option value="">Todos os convênios</option>
              <option v-for="(insurance, i) in insurances"
                      :value="insurance.plan.id" :key="i">{{ insurance.customName }}
              </option>
            </select>
          </div>
          <div class="column col-7 col-md-12 col-sm-12 form-group">
            <label class="form-label">Status</label>
            <div class="btn-group btn-group-block">
              <button class="btn btn-icon-left"
                      :class="hasStatusOpen ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('open')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusOpen" />Aberto
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusInAppeal ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('in_appeal')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusInAppeal" />Recurso
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusInBilling ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('in_billing')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusInBilling" />Faturando
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusClosed ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('closed')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusClosed" />Fechada
              </button>
              <button class="btn btn-icon-left"
                      :class="hasStatusFinished ? 'btn-gray' : 'btn-neutral'"
                      @click="setStatus('finished')">
                <fa-icon :icon="['fal', 'check']" v-if="hasStatusFinished" />Finalizada
              </button>
            </div>
          </div>
          <div class="column col-5 col-md-12 col-sm-12">
            <label class="form-label">Profissional</label>
            <select class="form-select"
                    v-model="filter.professionalId"
                    :disabled="!filter.insurancePlanId">
              <option value="">[Todos os profissionais]</option>
              <option v-for="(professional, i) in professionals"
                      :value="professional.id" :key="i">{{ professional.name }}</option>
            </select>
          </div>
          <div class="column">
            <label for="filter-search" class="form-label">Pesquisar</label>
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search"
                     class="form-input" maxlength="100"
                     placeholder="Informe o código da fatura"
                     v-model="filter.search">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto" style="display: flex; align-items: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    :class="{loading}"
                    :disabled="loading"
                    @click="reload">
              <fa-icon :icon="['fal', 'sync']" />
              Atualizar
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Faturas</p>
              <p class="empty-subtitle">
                Nenhuma fatura encontrada. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <div v-for="invoice in data.items" :key="invoice.id">
              <invoice-item
                :data="invoice"
                @removeInvoice="removeInvoice"
                @reload="load"
              />
            </div>
            <div class="text-center load-more">
              <button
                v-if="data.hasMore"
                class="btn btn-primary"
                @click="loadMore"
                :disabled="loadingMore"
                :class="{ loading: loadingMore }"
              >Carregar mais</button>
            </div>
          </template>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <span>Quantidade de faturas:
          <span class="text-primary text-bold">
            {{ data.items.length }}
          </span>
        </span>
        <span class="ml-2">Valor total:
          <strong class="text-primary">
            {{ total | currency }}
          </strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as professionalApi from '@/app/professional/api';
import InvoiceItem from './InvoiceItem.vue';

export default {
  components: {
    InvoiceItem,
  },
  data() {
    return {
      debounceTimeout: null,
      insurances: [],
      professionals: [],
      loading: false,
      loadingMore: false,
      filter: {
        insurancePlanId: '',
        professionalId: '',
        statuses: ['in_billing'],
      },
      data: {
        items: [],
        hasMore: false,
        offset: 0,
        limit: 30,
      },
    };
  },
  mounted() {
    this.loadInsurances();
    this.loadProfessionals();
    this.load();
  },
  watch: {
    'filter.insurancePlanId': function changeInsurance() {
      this.filter.professionalId = '';
    },
  },
  computed: {
    hasStatusOpen() {
      return this.filter.statuses.find(item => item === 'open');
    },
    hasStatusInBilling() {
      return this.filter.statuses.find(item => item === 'in_billing');
    },
    hasStatusClosed() {
      return this.filter.statuses.find(item => item === 'closed');
    },
    hasStatusInAppeal() {
      return this.filter.statuses.find(item => item === 'in_appeal');
    },
    hasStatusFinished() {
      return this.filter.statuses.find(item => item === 'finished');
    },
    total() {
      return this.data.items.reduce((a, b) => a + b.value.total, 0);
    },
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        statuses: this.filter.statuses.join(','),
        hasQuantity: true,
      };

      if (this.filter.insurancePlanId) {
        params.insurancePlanId = this.filter.insurancePlanId;
      }

      if (this.filter.professionalId) {
        params.professionalId = this.filter.professionalId;
      }

      if (this.filter.search) {
        params.search = this.filter.search;
      }

      return this.$http.get('/billing-invoices', { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch()
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    removeInvoice(invoice) {
      if (this.data.items && this.data.items.length > 0) {
        const found = this.data.items
          .find(({ id }) => id === invoice.id);
        const idx = this.data.items.indexOf(found);
        this.data.items.splice(idx, 1);
      }
    },
    setStatus(status) {
      const found = this.filter.statuses.find(item => item === status);
      if (found) {
        if (this.filter.statuses.length > 1) {
          this.filter.statuses.splice(this.filter.statuses.indexOf(found), 1);
        }
      } else {
        this.filter.statuses.push(status);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.billing-invoices-page {
  .load-more {
    margin-top: $layout-spacing-lg;
  }
}
</style>
