<template>
  <dx-modal title="Recalcular valores"
            :value="show" @input="close(false)" id="recalculate-invoice-modal">
    <div class="item-alert">
      <fa-icon :icon="['fal', 'exclamation-triangle']" class="mr-2" />
      <strong>Atenção. Ação irreversível!</strong>
      <div class="mt-1">
        Utilize as opções abaixo para atualizar apenas os campos selecionados e período específico.
        <strong>Esta ação não poderá ser desfeita.</strong>
      </div>
    </div>
    <div class="fields-group">
      <div class="columns">
        <div class="column col-12">
          <label class="form-switch">
            <input type="checkbox" v-model="requiredDate">
            <i class="form-icon"></i>Informar período
          </label>
        </div>
        <div class="column col-6 col-sm-12 form-group"
             :class="{'has-error': $v.filter.startDate.$error}">
          <label class="form-label">Data inicial</label>
          <div class="input-group">
            <dx-input-date
              id="start-date"
              class="form-input text-center"
              v-model="filter.startDate"
              :disabled="!requiredDate"
              @blur="$v.filter.startDate.$touch()"
            />
          </div>
          <template v-if="$v.filter.startDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.filter.startDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.filter.startDate.date">Data inválida</div>
          </template>
        </div>
        <div class="column col-6 col-sm-12 form-group"
             :class="{'has-error': $v.filter.endDate.$error}">
          <label class="form-label">Data final</label>
          <div class="input-group">
            <dx-input-date
              id="end-date"
              class="form-input text-center"
              v-model="filter.endDate"
              :disabled="!requiredDate"
              @blur="$v.filter.endDate.$touch()"
            />
          </div>
          <template v-if="$v.filter.endDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.filter.endDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.filter.endDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.filter.endDate.minDate">Data inferior a inicial</div>
          </template>
        </div>
      </div>
      <div class="divider"></div>
      <strong>Campos que serão atualizados</strong>
      <div class="columns mt-1">
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.procedure">
            <i class="form-icon"></i>Código do procedimento
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.professional">
            <i class="form-icon"></i>Profissional credenciado
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.fee">
            <i class="form-icon"></i>Impostos e taxas
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.operationalCost">
            <i class="form-icon"></i>Custo operacional
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.film">
            <i class="form-icon"></i>Filme
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.insurance">
            <i class="form-icon"></i>Valor do convênio
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.particular">
            <i class="form-icon"></i>Valor do paciente
          </label>
        </div>
        <div class="column col-6">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.transfer">
            <i class="form-icon"></i>Valor do repasse
          </label>
        </div>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-2"
              :class="{loading: saving}"
              :disabled="saving"
              @click="save">
        Recalcular
      </button>
      <button class="btn" @click="close(false)">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { date, minDate } from '../../../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
    invoiceIds: {
      type: Array,
    },
  },
  data() {
    return {
      saving: false,
      requiredDate: false,
      filter: {
        startDate: '',
        endDate: '',
        procedure: true,
        professional: true,
        fee: true,
        operationalCost: true,
        film: true,
        insurance: true,
        particular: true,
        transfer: true,
      },
    };
  },
  validations() {
    const rules = {
      filter: {
        startDate: { date },
        endDate: { date },
      },
    };

    if (this.requiredDate) {
      rules.filter.startDate = { required, date };
      rules.filter.endDate = { required, date };
      if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
        rules.filter.endDate = {
          required,
          date,
          minDate: minDate(this.filter.startDate),
        };
      }
    }

    return rules;
  },
  watch: {
    requiredDate: function clearDate() {
      this.filter.startDate = '';
      this.filter.endDate = '';
      this.$v.filter.$reset();
    },
  },
  methods: {
    close(loadInvoice = false) {
      this.$emit('close', loadInvoice);
    },
    save() {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return;
      }

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente atualizar essa fatura?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.saving = true;
              close();
              const data = {
                invoiceIds: this.invoiceIds,
                startDate: this.filter.startDate,
                endDate: this.filter.endDate,
                procedure: this.filter.procedure,
                professional: this.filter.professional,
                fee: this.filter.fee,
                operationalCost: this.filter.operationalCost,
                film: this.filter.film,
                insurance: this.filter.insurance,
                particular: this.filter.particular,
                transfer: this.filter.transfer,
              };
              this.$http
                .put('/recalculate-accounts', data)
                .then(() => {
                  this.$toast.show('Fatura atualizada com sucesso!');
                  this.close(true);
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro ao atualizar os valores!');
                })
                .finally(() => {
                  this.saving = false;
                });
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../../../assets/scss/variables';
@import 'src/assets/scss/colors';
  #recalculate-invoice-modal {
    .item-alert {
      background-color: $red-color-100;
      border: $border-width solid $red-color-300;
      border-radius: $border-radius;
      font-weight: 500;
      margin: $layout-spacing 0;
      padding: $layout-spacing;
      svg {
        color: $red-color-600;
      }
    }
    .fields-group {
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      padding: $layout-spacing;
    }
  }
</style>
