<template>
  <div class="page-container billing-accounts-page">
    <portal to="page-name">Contas</portal>
    <div class="card card-page">
      <div class="card-header">
        <label class="form-switch float-right">
          <input type="checkbox" v-model="filter.detailedMode" />
          <i class="form-icon"/>Modo detalhado
        </label>
        <h1 class="card-title">Contas</h1>
      </div>
      <div class="card-body">
        <div class="filter">
          <div class="columns">
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label">Convênio</label>
              <select id="filter-insurance" class="form-select"
                      v-model="filter.insurancePlanId">
                <option value="">Selecione o convênio</option>
                <option v-for="(item, i) in insurances"
                        :value="item.id" :key="i">{{ item.name }}</option>
              </select>
            </div>
            <div class="column col-4 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.filter.startDate.$error || $v.filter.endDate.$error}">
              <label class="form-label">Período</label>
              <div class="input-group">
                              <dx-dropdown
                                class="input-group-addon"
                                :items="periodOptions"
                                label="text"
                                @select="setPeriod"
                                direction="left"
                              ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
                <dx-input-date
                  id="start-date"
                  class="form-input text-center"
                  v-model="filter.startDate"
                  @change="changedStartDate"
                  @blur="$v.filter.startDate.$touch()"
                />
                <dx-input-date
                  id="end-date"
                  class="form-input text-center"
                  v-model="filter.endDate"
                  @change="changedEndDate"
                  @blur="$v.filter.endDate.$touch()"
                />
              </div>
            </div>
            <div class="column col-4">
              <label class="form-label">Profissional</label>
              <select id="filter-professional" class="form-select"
                      v-model="filter.professionalId">
                <option value="">Todos os profissionais</option>
                <option v-for="(item, i) in professionals"
                        :value="item.id" :key="i">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-label">Status</label>
              <div class="btn-group btn-group-block">
                <button class="btn btn-icon-left"
                        :class="hasStatusOpen ? 'btn-gray' : 'btn-neutral'"
                        @click="setStatus('open')">
                  <fa-icon :icon="['fal', 'check']" v-if="hasStatusOpen" />Aberto
                </button>
                <button class="btn btn-icon-left"
                        :class="hasStatusInAppeal ? 'btn-gray' : 'btn-neutral'"
                        @click="setStatus('in_appeal')">
                  <fa-icon :icon="['fal', 'check']" v-if="hasStatusInAppeal" />Recurso
                </button>
                <button class="btn btn-icon-left"
                        :class="hasStatusInBilling ? 'btn-gray' : 'btn-neutral'"
                        @click="setStatus('in_billing')">
                  <fa-icon :icon="['fal', 'check']" v-if="hasStatusInBilling" />Faturando
                </button>
                <button class="btn btn-icon-left"
                        :class="hasStatusClosed ? 'btn-gray' : 'btn-neutral'"
                        @click="setStatus('closed')">
                  <fa-icon :icon="['fal', 'check']" v-if="hasStatusClosed" />Fechada
                </button>
                <button class="btn btn-icon-left"
                        :class="hasStatusFinished ? 'btn-gray' : 'btn-neutral'"
                        @click="setStatus('finished')">
                  <fa-icon :icon="['fal', 'check']" v-if="hasStatusFinished" />Finalizada
                </button>
              </div>
            </div>
            <div class="column col-4">
              <label class="form-label">Pesquisar</label>
              <input type="text"
                     id="filter-search"
                     class="form-input"
                     placeholder="Fatura, Lote, Protocolo, NF, Senha, Guia, Carteirinha..."
                     v-model="filter.search"
              >
            </div>
            <div class="column" style="display: flex; align-items: flex-end">
              <button class="btn btn-icon btn-icon-left btn-primary btn-block mb-2"
                      :disabled="hasFilter"
                      @click="reload">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
                Pesquisar
              </button>
            </div>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading" />
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Contas</p>
              <p class="empty-subtitle">
                Nenhuma conta encontrada. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <div class="account-items" v-for="(item, i) in data.items" :key="i">
              <div class="columns">
                <div class="column col-2">
                  <small>Fatura: </small>
                  <strong class="text-secondary">{{ item.invoice.code }}</strong><br>
                  <small>Data: </small>{{ item.startDate | date }}<br>
                  <small>Status: </small>
                  <strong>{{ getStatusName(item.status) }}</strong>
                </div>
                <div class="column col-2">
                  <small>Lote: </small>{{ item.lot }}<br>
                  <small>Protocolo: </small>{{ item.protocol }}<br>
                  <small>Nota Fiscal: </small>{{ item.bill }}
                </div>
                <div class="column col-2">
                  <small>Guia: </small>
                  {{ item.referral.insurance || item.referral.provider }}<br>
                  <small>Senha: </small>
                  {{ item.authorization ? item.authorization.password : '-' }}<br>
                  <small>Tipo: </small>{{ getReferralTypeName(item.referral.type) }}
                </div>
                <div class="column">
                  <small>Paciente: </small>
                  <strong class="text-secondary">
                    {{ item.insurance.insured.name || item.patient.name }}
                  </strong><br>
                  <small>Carteirinha: </small>{{ item.insurance.insured.record }}<br>
                  <small>Validade: </small>{{ item.insurance.insured.validity | date }}
                </div>
                <div class="column col-2 text-right">
                  <small>Valor total: </small><strong>{{ item.total | currency }}</strong><br>
                  <button
                    class="btn btn-icon btn-icon-left btn-secondary btn-sm mt-2"
                    :class="{loading: accountModal.loading && idx === i}"
                    :disabled="accountModal.loading && idx === i"
                    @click="openAccountModal(item.id, i)">
                    <fa-icon :icon="['fal', 'pencil']" />
                    Editar
                  </button>
                </div>
              </div>
              <template v-if="filter.detailedMode">
                <div class="divider" />
                <table class="table">
                  <thead>
                  <tr>
                    <th>Data | Status</th>
                    <th>Procedimento | Profissional</th>
                    <th class="text-center">Retorno | Quantidade</th>
                    <th class="text-right">Convênio | Paciente</th>
                    <th class="text-right">Total | Repasse</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="expense in item.expenses" :key="expense.id">
                    <td style="width: 130px">
                      {{ expense.startDate | date }}<br>
                      <span class="text-info" v-if="expense.financialBundleId">
                      Repasse realizado
                    </span>
                      <span class="text-error" v-else>Repasse pendente</span>
                    </td>
                    <td>
                      {{ expense.expense.name }}<br>
                      {{ expense.professional.name }}
                      <span v-if="expense.professional.accredited">
                    (cred: {{ expense.professional.accredited.name}})
                  </span>
                    </td>
                    <td class="text-center" style="width: 150px">
                      {{ expense.return ? 'Sim' : 'Não' }}<br>
                      {{ expense.quantity | number }}
                    </td>
                    <td class="text-right" style="width: 150px">
                      {{ expense.value.insurance | currency }}<br>
                      {{ expense.value.particular | currency }}
                    </td>
                    <td class="text-right" style="width: 150px">
                      {{ expense.value.total | currency }}<br>
                      {{ expense.value.transferType === 'value' ? 'R$' : '%' }}
                      {{ expense.value.transferValue | number }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>
            <div class="text-center load-more" v-if="data.hasMore">
              <button
                class="btn btn-primary"
                @click="loadMore"
                :disabled="loadingMore"
                :class="{ loading: loadingMore }"
              >Carregar mais</button>
            </div>
          </template>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <span>Quantidade de contas:
          <strong class="text-primary">
            {{ data.items.length }}
          </strong>
        </span>
        <span class="ml-2">Valor total:
          <strong class="text-primary">
            {{ total | currency }}
          </strong>
        </span>
      </div>
    </div>
    <referral-modal :show="accountModal.show"
                    :data="accountModal.data"
                    source="billing-invoices"
                    :type="accountModal.type"
                    v-if="accountModal.show"
                    @close="closeAccountModal"
    />
  </div>
</template>

<script>
import { getName as getStatusName } from 'src/data/invoice-statuses';
import { getName as getReferralTypeName } from 'src/data/referral-types';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import ReferralModal from '@/app/appointment/components/modals/Referral.vue';
import { UPDATE_ACCOUNT } from '@/data/actions/modules/financial';
import { mapActions } from 'vuex';

export default {
  components: {
    ReferralModal,
  },
  data() {
    return {
      getStatusName,
      getReferralTypeName,
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      insurances: [],
      periodOptions: [],
      professionals: [],
      idx: null,
      filter: {
        detailedMode: false,
        insurancePlanId: '',
        professionalId: '',
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
        statuses: [
          'open',
          'in_appeal',
          'in_billing',
          'closed',
          'finished',
        ],
        search: '',
      },
      data: {
        items: [],
        hasMore: false,
        offset: 0,
        limit: 100,
      },
      accountModal: {
        type: '',
        data: {},
        loading: false,
        show: false,
      },
    };
  },
  mounted() {
    this.loadPeriod();
    this.loadInsurances();
    this.loadProfessionals();
    this.load();
  },
  computed: {
    hasStatusOpen() {
      return this.filter.statuses.find(item => item === 'open');
    },
    hasStatusInBilling() {
      return this.filter.statuses.find(item => item === 'in_billing');
    },
    hasStatusClosed() {
      return this.filter.statuses.find(item => item === 'closed');
    },
    hasStatusInAppeal() {
      return this.filter.statuses.find(item => item === 'in_appeal');
    },
    hasStatusFinished() {
      return this.filter.statuses.find(item => item === 'finished');
    },
    hasFilter() {
      return !(this.filter.insurancePlanId && this.filter.startDate && this.filter.endDate);
    },
    canAccessUpdateAccount() {
      if (UPDATE_ACCOUNT) {
        return this.$can(UPDATE_ACCOUNT);
      }
      return true;
    },
    total() {
      return this.data.items.reduce((a, b) => a + b.total, 0);
    },
  },
  validations() {
    const rules = {
      filter: {
        insurancePlanId: { required },
        statuses: { required },
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return null;
      }

      const duration = moment.duration(moment(this.filter.endDate)
        .diff(moment(this.filter.startDate))).asMonths();

      if (duration > 3) {
        this.$toast.show('O intervalo entre as datas não pode ser superior a 3 meses!',
          { type: 'error' });
        return false;
      }

      const firstData = this.data.offset === 0;
      const insurance = this.insurances
        .find(item => item.id === this.filter.insurancePlanId);

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        insuranceId: insurance.insuranceId,
        insurancePlanId: insurance.id,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        statuses: this.filter.statuses.join(','),
      };

      if (this.filter.professionalId) {
        params.professionalId = this.filter.professionalId;
      }

      if (this.filter.search) {
        params.search = this.filter.search.trim();
      }

      return this.$http.get('/billing-accounts', { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch()
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items.map(item => ({
            id: item.plan.id,
            insuranceId: item.id,
            type: item.type,
            name: item.customName,
          }));
        })
        .catch(() => {});
    },
    loadPeriod() {
      const current = moment().add(3, 'month').toDate();
      for (let i = 0; i < 12; i += 1) {
        this.periodOptions.push(
          {
            value: moment(current).subtract(i, 'month').toDate(),
            text: moment(current).subtract(i, 'month').format('MM/YYYY'),
          },
        );
      }
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.professionals = items;
        })
        .catch(() => {});
    },
    openAccountModal(accountId, i) {
      if (this.accountModal.loading) {
        return null;
      }

      if (!this.canAccessUpdateAccount) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return null;
      }

      this.accountModal.loading = true;
      this.idx = i;

      let account;
      return this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          data.invoiceId = data.invoice.id;
          account = data;
          this.accountModal.type = account.referral.type || account.patient.insurance.type;

          this.addExpenseRules(account)
            .finally(() => {
              this.accountModal.data = account;
              this.accountModal.show = true;
            });
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.accountModal.loading = false;
        });
    },
    closeAccountModal(account) {
      if (account) {
        const foundAccount = this.data.items.find(({ id }) => id === account.id);
        foundAccount.startDate = account.startDate;
        foundAccount.endDate = account.endDate;
        foundAccount.referral.type = account.referral.type;
        foundAccount.referral.provider = account.referral.provider;
        foundAccount.referral.insurance = account.referral.insurance;
        foundAccount.referral.main = account.referral.main;
        foundAccount.serviceType = account.serviceType;
        if (account.authorization && account.authorization.password) {
          foundAccount.authorization.password = account.authorization.password;
        }
        account.expenses.forEach((item) => {
          item.expense = {
            id: item.id,
            name: item.name,
          };
          item.id = item.itemId;
          return item;
        });
        foundAccount.expenses = account.expenses;
        foundAccount.total = account.expenses.reduce((a, b) => a + b.value.total, 0);
      }
      this.accountModal.show = false;
    },
    addExpenseRules(account) {
      const planId = account.patient.insurance.plan.id;

      const params = {
        expenseIds: account.expenses.map(({ id }) => id).join(','),
      };
      return this.$http.get(`/expense-insurances/${planId}/rules`, { params })
        .then(({ data }) => {
          if (data.items) {
            if (data.items.length > 0) {
              data.items.forEach((item) => {
                account.expenses.forEach((expense) => {
                  if (item.id === expense.id) {
                    if (item.rules) {
                      expense.rules = item.rules;
                    }
                  }
                });
              });
            }
          }
        });
    },
    setPeriod({ value }) {
      this.filter.startDate = moment(value)
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.endDate = moment(value)
        .endOf('month')
        .format('YYYY-MM-DD');
    },
    setStatus(status) {
      const found = this.filter.statuses.find(item => item === status);
      if (found) {
        if (this.filter.statuses.length > 1) {
          this.filter.statuses.splice(this.filter.statuses.indexOf(found), 1);
        }
      } else {
        this.filter.statuses.push(status);
      }
    },
    changedStartDate() {
      if (!this.filter.startDate) {
        this.filter.startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.endDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
    },
    changedEndDate() {
      if (!this.filter.startDate && !this.filter.endDate) {
        this.filter.startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.endDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (!this.filter.endDate) {
        this.filter.endDate = moment(this.filter.startDate)
          .endOf('month')
          .format('YYYY-MM-DD');
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

.billing-accounts-page {
  .account-items {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    font-size: $font-size-sm;
    margin: $layout-spacing 0;
    padding: $layout-spacing-sm;
    .table {
      background-color: #fff;
      th, td {
        font-size: 0.5rem;
      }
    }
    &:hover {
      border: $border-width solid $border-color-dark;
    }
  }
  .load-more {
    margin-top: $layout-spacing-lg;
  }
}
</style>
