<template>
  <div class="billing-attendance-item">
    <div class="columns attendance-item">
      <div class="column col-auto c-hand" @click="selected = !selected">
        <span :class="selected ? 'text-error' : 'text-primary'">
          <fa-icon :icon="['fal', selected ? 'chevron-up' : 'chevron-down']"/>
        </span>
      </div>
      <div class="column col-auto" style="width: 100px">
        {{ data.startDate | date }}
      </div>
      <div class="column">
        <span class="hover-link text-secondary"
              :class="selected ? 'text-warning text-bold' : 'text-primary'"
              @click="selected = !selected">
          {{ data.patientName }}
        </span>
      </div>
      <div class="column col-3 hide-sm">
        {{ data.insuranceCustomName }}
      </div>
      <div class="column col-2 hide-md hide-sm text-small">
        {{ expenseNames() }}
      </div>
      <div class="column col-2 col-sm-3 text-right">
        {{ data.total | currency }}
      </div>
    </div>
    <template v-if="selected">
      <div class="billing-attendance-detail">
        <div class="loading" v-if="loading"></div>
        <div class="card card-page" v-else>
          <div class="card-body">
            <h6>Dados do agendamento</h6>
            <div class="columns">
              <div class="column col-2 col-md-12 col-sm-12">
                <small>Data</small><br>
                <span>{{ detail.date | date('DD/MM/YYYY HH:mm') }}</span>
              </div>
              <div class="column">
                <small>Profissional</small><br>
                <span>{{ detail.professional.name | capitalizeName }}</span>
              </div>
              <div class="column col-3 col-md-12 col-sm-12">
                <small>Status</small><br>
                <strong class="text-primary">{{ statuses.getName(detail.status) }}</strong>
              </div>
            </div>
            <div class="divider"/>
            <h6>Dados do paciente</h6>
            <div class="columns">
              <div class="column col-2 col-md-12 col-sm-12">
                <small>CPF</small><br>
                <span v-if="detail.patient.identity">
                  {{ detail.patient.identity | cpf }}
                </span>
                <span v-else>-</span>
              </div>
              <div class="column col-md-12 col-sm-12">
                <small>Nome</small><br>
                <router-link :to="patientLink" target="_blank" class="text-primary">
                  {{ detail.patient.name | capitalizeName }}
                  <fa-icon :icon="['fal', 'external-link']"></fa-icon>
                </router-link>
              </div>
              <div class="column col-3 col-md-12 col-sm-12">
                <small>Data de nascimento</small><br>
                <span>{{ detail.patient.birthDate | date }}</span>
              </div>
              <div class="column col-3 col-md-12 col-sm-12">
                <small>Convênio</small><br>
                <span>{{ detail.patient.insurance.name | capitalizeName }}</span>
              </div>
            </div>
            <template v-if="detail.accounts && detail.accounts.length > 0">
              <div class="divider"/>
              <h6>Dados da finalização</h6>
              <div v-for="(account, i) in detail.accounts" :key="i">
                <div class="columns">
                  <div class="column col-2 col-md-12 col-sm-12">
                    <small>Fatura</small><br>
                    {{ account.invoice ? account.invoice.code : '' }}
                  </div>
                  <div class="column col-2 col-md-12 col-sm-12">
                    <small>Conta</small><br>
                    {{ account.code }}
                  </div>
                  <div class="column col-2 col-md-12 col-sm-12">
                    <small>Guia</small><br>
                    {{ account.referral ? account.referral.insurance : '' }}
                  </div>
                  <div class="column col-md-12 col-sm-12">
                    <small>Profissional executante</small><br>
                    {{ account.expenses && account.expenses.length > 0
                      ? account.expenses[0].professional.name : '' }}
                  </div>
                  <div class="column col-2 col-md-12 col-sm-12">
                    <small>Status</small><br>
                    {{ invoiceStatus.getName(account.status) }}
                  </div>
                </div>
              </div>
            </template>
            <div class="divider"/>
            <h6>Procedimentos</h6>
            <div class="empty" v-if="detail.expenses.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h5">Procedimentos</p>
              <p class="empty-subtitle">
                Nenhum procedimento foi cadastrado para este paciente.
              </p>
            </div>
            <table class="table" v-else>
              <thead>
              <tr>
                <th class="text-center">Status</th>
                <th>Procedimento</th>
                <th class="text-center">Qtde</th>
                <th class="text-right">Unitário</th>
                <th class="text-right">Valor bruto</th>
                <th class="text-right">Valor recebido</th>
                <th class="text-right">Valor líquido</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="expense in detail.expenses" :key="expense.key">
                <td class="text-center" width="60px">
                  <span class="text-error tooltip" data-tooltip="Não finalizado"
                        v-if="expense.accountId === null">
                    <fa-icon :icon="['fal', 'times-circle']"></fa-icon>
                  </span>
                  <span class="text-secondary tooltip" data-tooltip="Finalizado"
                        v-else>
                    <fa-icon :icon="['fal', 'check-circle']"></fa-icon>
                  </span>
                </td>
                <td>{{ expense.code | tuss }} - {{ expense.name | capitalizeName }}</td>
                <td class="text-center" width="60px">
                <span class="tooltip" data-tooltip="Quantidade">
                  x{{ expense.quantity }}
                </span>
                </td>
                <td class="text-right" width="80px">
                <span class="tooltip" data-tooltip="valor unitário">
                  {{ expense.value.unit | currency }}
                </span>
                </td>
                <td class="text-right" width="100px">
                <span class="tooltip" data-tooltip="valor bruto">
                  {{ expense.value.gross | currency }}
                </span>
                </td>
                <td class="text-right" width="100px">
                <span class="tooltip" data-tooltip="valor recebido">
                  {{ expense.value.total | currency }}
                </span>
                </td>
                <td class="text-right" width="100px">
                <span class="tooltip" data-tooltip="valor líquido">
                  {{ expense.value.net | currency }}
                </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import * as types from 'src/data/appointment-types';
import * as statuses from 'src/data/appointment-statuses';
import * as invoiceStatus from 'src/data/invoice-statuses';

export default {
  props: {
    data: {},
  },
  data() {
    return {
      loading: false,
      types,
      statuses,
      invoiceStatus,
      selected: false,
      detail: {
        id: '',
        type: '',
        date: '',
        status: '',
        professional: {
          id: '',
          name: '',
        },
        patient: {
          id: '',
          identity: '',
          name: '',
          birthDate: '',
          insurance: {
            id: '',
            name: '',
          },
        },
        expenses: [],
        accounts: [],
      },
    };
  },
  watch: {
    selected: function loadDetail() {
      if (this.selected) {
        this.load();
      }
    },
  },
  computed: {
    patientLink() {
      const date = moment(this.detail.date).format('YYYY-MM-DD');
      const name = encodeURIComponent(this.detail.patient.name);
      return `/appointments?date=${date}&s=${name}&scheduleId=ALL`;
    },
  },
  methods: {
    async load() {
      this.loading = true;
      await this.$http.get(`/appointments/${this.data.id}`)
        .then(({ data }) => {
          this.detail.id = data.id;
          this.detail.type = data.type;
          this.detail.date = data.startDate;
          this.detail.status = data.status;
          this.detail.accounts = data.accounts;
          this.detail.professional = {
            id: data.professional.id,
            name: data.professional.name,
          };
          this.detail.patient = {
            id: data.patient.id,
            identity: data.patient.identity
              ? data.patient.identity.value
              : null,
            name: data.patient.name,
            birthDate: data.patient.birthDate,
            insurance: {
              id: data.insurance.id,
              name: data.insurance.name,
            },
          };
          this.detail.expenses = data.expenses.map(expense => ({
            id: expense.id,
            code: expense.code,
            name: expense.name,
            accountId: expense.accountId,
            quantity: expense.quantity,
            value: {
              unit: expense.value.particular + expense.value.insurance,
              gross: ((expense.value.insurance * (expense.value.factor || 1))
                + expense.value.particular) * expense.quantity,
              total: ((expense.value.insurance * (expense.value.factor || 1))
                + expense.value.particular) * expense.quantity
                - (expense.value.discount || 0)
                + (expense.value.interest || 0),
              net: ((expense.value.insurance * (expense.value.factor || 1))
                  + expense.value.particular) * expense.quantity
                - (expense.value.discount || 0)
                + (expense.value.interest || 0)
                - (expense.value.rate || 0),
            },
          }));
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    expenseNames() {
      return this.data.expenses
        .map(expense => `${expense.return ? '[Retorno]' : ''} ${expense.name}`).join(',');
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

.billing-attendance-item {
  .attendance-item {
    border-bottom: $border-color solid $border-width;
    margin: 0 calc($layout-spacing-sm / 5);
    padding: $layout-spacing 0;

    &:hover {
      background-color: $gray-color-ultra-light;
    }
  }

  .billing-attendance-detail {
    background-color: $gray-color-ultra-light;
    padding: $layout-spacing-lg;

    .card {
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      box-shadow: none;

      .card-body {
        .divider {
          margin: $layout-spacing-lg;
        }
      }
    }
  }
}
</style>
