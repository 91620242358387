<template>
  <div class="billing-invoice-item">
    <div class="insurance-title">
      <div class="text-bold">{{ customName(invoice.insurance) }}</div>
      <div>
        <button class="btn btn-sm btn-gray btn-icon btn-action text-center tooltip mr-1"
                data-tooltip="Detalhar contas"
                :disabled="disabledActions"
                @click="openAccountModal">
          <fa-icon :icon="['fal', 'file-invoice-dollar']" />
        </button>
        <button class="btn btn-sm btn-gray btn-icon btn-action text-center tooltip mr-1"
                :disabled="disabledActions"
                data-tooltip="Imprimir fatura"
                @click="openPrintModal">
          <span class="loading text-normal" v-if="printing" />
          <fa-icon :icon="['fal', 'print']" v-else />
        </button>
        <button class="btn btn-sm btn-gray btn-icon btn-action text-center tooltip mr-1"
                :disabled="disabledActions"
                data-tooltip="TISS XML"
                @click="openTissXmlModal"
                v-if="invoice.insurance.type === 'insurance'">
          <span class="loading text-normal" v-if="downloading" />
          <fa-icon :icon="['fal', 'cloud-download']" v-else />
        </button>
        <button class="btn btn-sm btn-gray btn-icon btn-action text-center tooltip mr-1"
                data-tooltip="Recalcular"
                :disabled="disabledActions || invoice.status === 'finished'"
                @click="openRecalculateInvoice">
          <fa-icon :icon="['fal', 'sync']" />
        </button>
        <button class="btn btn-sm btn-gray btn-icon btn-action text-center tooltip mr-1"
                data-tooltip="Salvar alterações"
                :disabled="disabledActions"
                @click="saveInvoice">
          <span class="loading text-normal" v-if="saving" />
          <fa-icon :icon="['fal', 'save']" v-else />
        </button>
        <button class="btn btn-sm btn-gray btn-icon btn-action text-center tooltip"
                data-tooltip="Excluir"
                :disabled="disabledDeleting"
                @click="deleteInvoice">
          <span class="loading text-normal" v-if="deleting" />
          <fa-icon :icon="['fal', 'times']" v-else />
        </button>
      </div>
    </div>
    <div class="insurance-card">
      <div class="columns">
        <div class="column col-1 text-center centered">
          <div class="insurance-image"
               v-if="invoice.insurance.type === 'particular'">
            <fa-icon :icon="['fal', 'hand-holding-usd']" size="2x"></fa-icon>
          </div>
          <div class="insurance-image" v-else
               :style="bgImage(invoice.insurance.imageUrl)">
          </div>
        </div>
        <div class="column col-3 col-md-12 col-sm-12 text-center form-group">
          <small>Número da fatura</small>
          <div class="text-bold c-hand" @click="openAccountModal">{{ invoice.code }}</div>
        </div>
        <div class="column col-3 col-md-12 col-sm-12 text-center form-group">
          <small>Contas</small>
          <div>{{ invoice.quantity }}</div>
        </div>
        <div class="column col-3 col-md-12 col-sm-12 text-center form-group">
          <small>Total da fatura</small>
          <div>{{ invoice.value ? invoice.value.total : 0 | currency }}</div>
        </div>
        <div class="column col-2 col-md-12 col-sm-12 text-center form-group">
          <small>Status</small>
          <div class="text-bold text-info">
            {{ invoiceStatus.getName(invoice.status) }}
          </div>
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-2 col-md-4 col-sm-12 form-group"
             :class="{'has-error': $v.invoice.deliveryDate.$error}" v-if="!isParticular">
          <small>Data da entrega</small><br>
          <dx-input-date id="invoice-delivery-date" class="form-input input-sm"
                         placeholder="00/00/0000" v-model="invoice.deliveryDate"
                         @blur="$v.invoice.deliveryDate.$touch()" />
        </div>
        <div class="column col-2 col-md-4 col-sm-12 form-group"
             :class="{'has-error': $v.invoice.receiptDate.$error}" v-if="!isParticular">
          <small>Data do recebimento</small><br>
          <dx-input-date id="invoice-receipt-date" class="form-input input-sm"
                         placeholder="00/00/0000" v-model="invoice.receiptDate"
                         @blur="$v.invoice.receiptDate.$touch()" />
        </div>
        <div class="column col-2 col-md-4 col-sm-12 form-group"
             :class="{'has-error': $v.invoice.paymentDate.$error}">
          <small>Data do repasse</small><br>
          <dx-input-date id="invoice-payment-date" class="form-input input-sm"
                         placeholder="00/00/0000" v-model="invoice.paymentDate"
                         @blur="$v.invoice.paymentDate.$touch()" />
        </div>
        <div class="column form-group">
          <small>Protocolo</small><br>
          <input class="form-input input-sm" type="text" v-model="invoice.protocol" />
        </div>
        <div class="column col-2 col-md-4 col-sm-12 form-group">
          <small>Lote</small><br>
          <input class="form-input input-sm" type="text" v-model="invoice.lot" />
        </div>
        <div class="column col-2 col-md-4 col-sm-12 form-group">
          <small>Nota fiscal</small><br>
          <input class="form-input input-sm" type="text" v-model="invoice.invoice" />
        </div>
      </div>
    </div>
    <dx-modal title="Faturamento TISS - Arquivo XML"
              id="modal-tiss-xml"
              v-model="tissXmlModal.show"
              size="lg">
      <div class="columns">
        <div class="column col-2 form-group">
          <small>Número da fatura</small>
          <div class="text-bold">{{ invoice.code }}</div>
        </div>
        <div class="column col-4 form-group">
          <small>Convênio</small>
          <div class="text-bold">{{ customName(invoice.insurance) }}</div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>#</th>
          <th>Identificação</th>
          <th>Mensagem de erro</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(error, i) in tissXmlModal.errors" :key="i">
          <td>{{ i + 1 }}</td>
          <td>
            {{ error.type }}
          </td>
          <td>{{ error.description }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-gray mr-2" @click="closeTissXmlModal">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Impressão da capa do lote"
              id="modal-invoice-print"
              v-model="printModal.show">
      <div class="columns card-group">
        <div class="column col-12 form-group">
          Selecione os valores que você deseja exibir na capa do lote
          <label class="form-switch mt-2">
            <input type="checkbox" v-model="printModal.value.insurance"/>
            <i class="form-icon"/> Exibir valor convênio
          </label>
          <label class="form-switch mt-2">
            <input type="checkbox" v-model="printModal.value.particular"/>
            <i class="form-icon"/> Exibir valor paciente
          </label>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                :class="{loading: printing}"
                @click="printInvoice('sheet')"
                :disabled="printing">
          <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
        </button>
        <button class="btn btn-primary btn-icon-left mr-1"
                :class="{loading: printing}"
                @click="printInvoice('pdf')"
                :disabled="printing">
          <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
        </button>
        <button class="btn" @click="closePrintModal">Sair</button>
      </template>
    </dx-modal>
    <account-modal
      v-if="accountModal.show"
      :show="accountModal.show"
      :invoice-id="invoice.id"
      @close="closeAccountModal"
    />
    <recalculate-invoice
      v-if="recalculateModalShow"
      :show="recalculateModalShow"
      :invoice-ids="[invoice.id]"
      @close="closeRecalculateModal"
    />
  </div>
</template>

<script>
import { customName } from 'src/helpers/insurance';
import * as invoiceStatus from 'src/data/invoice-statuses';
import { mergeFrom } from '@/helpers/object';
import moment from 'moment';
import { date, minDate } from '@/data/validators';
import { required } from 'vuelidate/lib/validators';
import {
  DELETE_BILLING,
  UPDATE_BILLING,
  PRINT_BILLING,
  GENERATE_BILLING_XML,
} from '@/data/actions/modules/financial';
import AccountModal from './Modals/Accounts.vue';
import RecalculateInvoice from './Modals/RecalculateInvoice.vue';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    AccountModal,
    RecalculateInvoice,
  },
  data() {
    return {
      invoice: this.blankInvoice(),
      invoiceStatus,
      customName,
      saving: false,
      downloading: false,
      deleting: false,
      printing: false,
      updating: false,
      recalculateModalShow: false,
      printModal: {
        value: {
          insurance: true,
          particular: false,
        },
        show: false,
      },
      accountModal: {
        show: false,
      },
      tissXmlModal: {
        errors: [],
        show: false,
      },
    };
  },
  validations() {
    return {
      invoice: {
        deliveryDate: {
          required,
          date,
        },
        receiptDate: {
          required,
          date,
          minDate: minDate(moment(this.invoice.deliveryDate).format('YYYY-MM-DD')),
        },
        paymentDate: {
          required,
          date,
          minDate: minDate(moment(this.invoice.deliveryDate).format('YYYY-MM-DD')),
        },
      },
    };
  },
  computed: {
    canAccessUpdateBilling() {
      if (UPDATE_BILLING) {
        return this.$can(UPDATE_BILLING);
      }
      return true;
    },
    canAccessRemoveBilling() {
      if (DELETE_BILLING) {
        return this.$can(DELETE_BILLING);
      }
      return true;
    },
    disabledActions() {
      return this.deleting
        || this.saving
        || this.invoice.quantity === 0
        || this.printing
        || this.downloading
        || this.updating;
    },
    disabledDeleting() {
      return this.invoice.status !== 'in_billing' || this.disabledActions;
    },
    canAccessPrintBilling() {
      if (PRINT_BILLING) {
        return this.$can(PRINT_BILLING);
      }
      return true;
    },
    canAccessGenerateXMLBilling() {
      if (GENERATE_BILLING_XML) {
        return this.$can(GENERATE_BILLING_XML);
      }
      return true;
    },
    isParticular() {
      return this.invoice.insurance.type === 'particular';
    },
  },
  mounted() {
    this.invoice = mergeFrom(this.blankInvoice(), this.data);
  },
  methods: {
    saveInvoice() {
      if (!this.canAccessUpdateBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$v.invoice.$touch();
      if (this.$v.invoice.$error) {
        this.$toast.show('Informe os campos obrigatórios da fatura', { type: 'error' });
        return;
      }

      if (this.disabledActions) return;

      this.saving = true;

      const data = {
        protocol: this.invoice.protocol,
        lot: this.invoice.lot,
        invoice: this.invoice.invoice,
        deliveryDate: this.invoice.deliveryDate,
        receiptDate: this.invoice.receiptDate,
        paymentDate: this.invoice.paymentDate,
      };

      this.$http
        .put(`/billing-invoices/${this.invoice.id}`, data)
        .then(() => {
          this.$toast.show('Fatura salva com sucesso');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteInvoice() {
      if (!this.canAccessRemoveBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (this.disabledActions) return;

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir esta fatura?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.deleting = true;
              this.$http
                .delete(`/billing-invoices/${this.invoice.id}`)
                .then(() => {
                  this.$emit('removeInvoice', {
                    id: this.invoice.id,
                  });
                  this.$toast.show('Fatura removida com sucesso!');
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    openAccountModal() {
      // if (this.invoice.quantity > 2000) {
      //   this.$toast.error('Existem muitas contas nesta fatura. Entre em contato com o suporte!');
      //   return;
      // }
      this.accountModal.show = true;
    },
    closeAccountModal(invoice) {
      this.invoice.quantity = invoice.quantity;
      this.invoice.status = invoice.status;
      this.invoice.value.total = invoice.accounts.reduce((a, b) => a + b.total, 0);

      if (this.invoice.quantity === 0) {
        this.$emit('removeInvoice', {
          id: this.invoice.id,
        });
      }
      this.accountModal.show = false;
    },
    closeRecalculateModal(recalculate) {
      if (recalculate) {
        this.$emit('reload');
      }
      this.recalculateModalShow = false;
    },
    async openTissXmlModal() {
      if (!this.canAccessGenerateXMLBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.downloading = true;
      await this.xmlGenerate();
      if (this.tissXmlModal.errors.length > 0) {
        this.tissXmlModal.show = true;
      } else {
        this.$toast.show('O arquivo XML foi gerado com sucesso!');
        this.tissXmlModal.show = false;
      }
    },
    closeTissXmlModal() {
      this.tissXmlModal.show = false;
      this.tissXmlModal.errors = [];
    },
    async xmlGenerate() {
      this.tissXmlModal.errors = [];
      await this.xmlValidate();
      if (this.tissXmlModal.errors.length === 0) {
        return this.xmlDownload();
      }
      this.downloading = false;
      return false;
    },
    async xmlValidate() {
      this.tissXmlModal.errors = [];
      await this.$http
        .get(`/tiss/${this.invoice.id}/xml-validate`, { code: this.invoice.code })
        .then(({ data }) => {
          this.tissXmlModal.errors = data.errors;
        })
        .catch((e) => {
          this.$toast.error(e);
          this.downloading = false;
          throw e;
        });
    },
    async xmlDownload() {
      return this.$file
        .download(`/tiss/${this.invoice.id}/xml`, { code: this.invoice.code })
        .then(() => {
          this.downloading = false;
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro ao gerar o arquivo XML!', { type: 'error' });
        });
    },
    openPrintModal() {
      if (!this.canAccessPrintBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.printModal.show = true;
    },
    closePrintModal() {
      this.printModal.show = false;
    },
    printInvoice(printType) {
      if (!this.canAccessPrintBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }

      if (this.printModal.value.particular === false
        && this.printModal.value.insurance === false) {
        this.$toast.show('Informe ao menos uma opção de exibição!', { type: 'error' });
        return null;
      }

      let type = '';
      if (this.printModal.value.particular && this.printModal.value.insurance) {
        type = 'co-participation';
      } else if (this.printModal.value.particular) {
        type = 'particular';
      } else {
        type = 'insurance';
      }

      this.printing = true;

      if (printType === 'sheet') {
        return this.$file
          .download(`/billing-invoices/${this.invoice.id}/print`,
            { printType, type },
            { method: 'POST' })
          .catch(() => {})
          .finally(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print(`/billing-invoices/${this.invoice.id}/print`,
          { printType, type },
          { method: 'POST' })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
    openRecalculateInvoice() {
      if (!this.canAccessUpdateBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      if (this.disabledActions) return;

      this.recalculateModalShow = true;
    },
    bgImage(image) {
      const backgroundImage = `url(${image})`;
      return backgroundImage ? { backgroundImage } : null;
    },
    blankInvoice() {
      return {
        id: '',
        code: '',
        status: '',
        protocol: '',
        lot: '',
        invoice: '',
        deliveryDate: '',
        receiptDate: '',
        paymentDate: '',
        quantity: '',
        insurance: {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
          imageUrl: '',
        },
        value: {
          appealed: '',
          recovered: '',
          total: '',
        },
        tiss: false,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.billing-invoice-item {
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  margin: $layout-spacing-lg 0;
  &:hover {
    border: $border-width solid $gray-color;
  }
  .insurance-title {
    align-items: center;
    background-color: $gray-color-ultra-light;
    border-bottom: $border-width solid $border-color;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: $layout-spacing-sm;
  }
  .insurance-card {
    padding: $layout-spacing-lg;
    .insurance-image {
      background: $light-color center no-repeat;
      background-size: contain;
      border-radius: $border-radius;
      height: 1.4rem;
      width: 3.4rem;
      svg {
        color: $gray-color-dark;
      }
    }
  }
}
</style>
