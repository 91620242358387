export const referral = {
  consultation: 'Consulta',
  sadt: 'SP-SADT',
  hospitalization: 'Hospitalar',
  referral: 'Encaminhamento',
  particular: 'Particular',
};

export function getName(value) {
  return referral[value];
}
