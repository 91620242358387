export default [
  {
    id: 'expense',
    name: 'Despesas',
  },
  {
    id: 'revenue',
    name: 'Receitas',
  },
];
