<template>
  <tr>
    <td class="text-center">{{ idx + 1 }}</td>
    <td>
      <div :class="{'has-error': $v.data.dueDate.$error}">
        <dx-input-date
          id="due-date"
          class="form-input"
          v-model="data.dueDate"
          @blur="$v.data.dueDate.$touch()"
        />
      </div>
    </td>
    <td>
      <div :class="{'has-error': $v.data.value.$error}">
        <dx-input-number id="current-value"
                         class="form-input text-right"
                         readonly="true"
                         v-model="data.value"
                         :precision="2"
                         @blur="$v.data.value.$touch()"
        />
      </div>
    </td>
    <td>
      <div :class="{'has-error': $v.data.methodId.$error}">
        <select id="method"
                class="form-select"
                v-model="data.methodId"
                @blur="$v.data.methodId.$touch()">
          <option value="">[Selecione uma forma]</option>
          <option v-for="item in methods" :value="item.id"
                  :key="item.id">{{ item.name }}</option>
        </select>
      </div>
    </td>
    <td>
      <div :class="{'has-error': $v.data.bankAccountId.$error}">
        <select id="bank-account"
                class="form-select"
                v-model="data.bankAccountId"
                @blur="$v.data.bankAccountId.$touch()">
          <option value="">[Selecione uma conta]</option>
          <option v-for="item in bankAccounts" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </td>
    <td>
      <select class="form-select" v-model="data.paid">
        <option :value="true">Sim</option>
        <option :value="false">Não</option>
      </select>
    </td>
    <td>
      <div :class="{'has-error': $v.data.paymentDate.$error}">
        <dx-input-date
          id="due-date"
          class="form-input"
          :disabled="!data.paid"
          v-model="data.paymentDate"
          @blur="$v.data.paymentDate.$touch()"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  date,
  minDate,
} from 'src/data/validators';

export default {
  props: {
    idx: {
      type: Number,
    },
    bankAccounts: {
      type: Array,
    },
    methods: {
      type: Array,
    },
    data: {
      issueDate: {
        type: Date,
      },
      dueDate: {
        type: Date,
      },
      paymentDate: {
        type: Date,
      },
      value: {
        type: Number,
      },
      methodId: {
        type: Number,
      },
      bankAccountId: {
        type: Number,
      },
      paid: {
        type: Boolean,
      },
    },
  },
  validations() {
    const rules = {
      data: {
        dueDate: { required, date, minDate: minDate(this.data.issueDate) },
        paymentDate: {},
        value: { required },
        methodId: { required },
        bankAccountId: { required },
      },
    };

    if (this.data.paid) {
      rules.data.paymentDate = { required, date, minDate: minDate(this.data.issueDate) };
    }

    return rules;
  },
  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
  },
};
</script>

<style lang="scss">

</style>
