<template>
  <div>
    <dx-modal title="Detalhes da fatura"
              :value="show" @input="close()"
              id="billing-invoice-detail-modal"
              size="xl">
      <div class="loading loading-lg" v-if="loading"></div>
      <template v-else>
        <div class="info-group">
          <div class="columns">
            <div class="column col-2 form-group">
              <small>Número da fatura</small>
              <div class="text-bold">{{ invoice.code }}</div>
            </div>
            <div class="column col-2 form-group">
              <small>Status</small>
              <div class="text-bold">{{ getStatusName(invoice.status) }}</div>
            </div>
            <div class="column col-6 form-group">
              <small>Convênio</small>
              <div class="text-bold">{{ customName(invoice.insurance) }}</div>
            </div>
            <div class="column col-2 text-right">
              <small>Qtde. contas</small><br>
              <span class="text-bold tooltip tooltip-left c-hand"
                    :class="differentAccounts ? 'text-warning' : ''"
                   data-tooltip="Contas filtradas / total de contas">
                {{ filteredAccounts.length }}/{{ invoice.accounts.length }}
              </span>
            </div>
            <div class="column col-2">
              <small>Data entrega</small>
              <div class="text-bold">{{ invoice.deliveryDate | date }}</div>
            </div>
            <div class="column col-2">
              <small>Data recebimento</small>
              <div class="text-bold">{{ invoice.receiptDate | date }}</div>
            </div>
            <div class="column col-2">
              <small>Data repasse</small>
              <div class="text-bold">{{ invoice.paymentDate | date }}</div>
            </div>
            <div class="column col-2 text-right">
              <small>Valor convênio</small>
              <div class="text-bold">{{ insuranceValue | currency }}</div>
            </div>
            <div class="column col-2 text-right">
              <small>Valor pago no caixa</small>
              <div class="text-bold">{{ particularValue | currency }}</div>
            </div>
            <div class="column col-2 text-right">
              <small>Total fatura</small>
              <div class="text-bold">{{ totalValue | currency }}</div>
            </div>
          </div>
          <div class="divider" />
          <small class="text-bold">Status da fatura</small>
          <div class="loading" v-if="updating" />
          <div class="bar" v-else>
            <div class="bar-item c-hand tooltip" style="width:20%;"
                 @click="setStatus(bar.name)"
                 :data-tooltip="bar.tooltip"
                 :class="bar.selected ? `status-${bar.name}` : 'status-default'"
                 v-for="(bar, i) in statusBar" :key="i">
              {{ bar.description }}
            </div>
          </div>
        </div>

        <div class="filter-group">
          <strong>Filtros</strong>
          <div class="columns">
            <div class="column form-group"
                 :class="{'has-error': $v.filter.startDate.$error}">
              <small>Data inicial</small>
              <dx-input-date id="filter-start-date" class="form-input input-sm"
                             autocomplete="nope"
                             v-model="filter.startDate"
                             @blur="$v.filter.startDate.$touch()"/>
              <template v-if="$v.filter.startDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.filter.startDate.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.filter.startDate.date">Data inválida
                </div>
              </template>
            </div>
            <div class="column form-group"
                 :class="{'has-error': $v.filter.endDate.$error}">
              <small>Data final</small>
              <dx-input-date id="filter-end-date" class="form-input input-sm"
                             autocomplete="nope"
                             v-model="filter.endDate"
                             @blur="$v.filter.endDate.$touch()"
              />
              <template v-if="$v.filter.endDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.filter.endDate.required">
                  Campo obrigatório
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.filter.endDate.date">Data inválida
                </div>
                <div class="form-input-hint"
                     v-else-if="!$v.filter.endDate.minDate">
                  A data inicial não pode ser menor que a data final
                </div>
              </template>
            </div>
            <div class="column form-group">
              <small>Guia de faturamento</small>
              <select id="filter-referral" class="form-select select-sm"
                      v-model="filter.referralType">
                <option value="">Todas as guias</option>
                <option value="consultation">Consulta</option>
                <option value="sadt">SP/SADT</option>
              </select>
            </div>
            <div class="column form-group">
              <small>Exibir retornos</small>
              <select id="filter-return" class="form-select select-sm"
                      v-model="filter.return">
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            <div class="column form-group">
              <small>Exibir cortesias</small>
              <select id="filter-courtesy" class="form-select select-sm"
                      v-model="filter.courtesy">
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            <div class="column col-3 form-group">
              <small>Recebimento do honorário</small>
              <select id="filter-referral" class="form-select select-sm"
                      v-model="filter.accredited">
                <option value="">Todas as formas</option>
                <option value="false">Recebido pela clínica/hospital [PJ]</option>
                <option value="true">Recebido pelo profissional [PF]</option>
              </select>
            </div>
          </div>
          <div class="columns">
            <div class="column col-4 form-group">
              <small>Paciente</small>
              <select class="form-select select-sm"
                      v-model="filter.patientId">
                <option value="">Todos os pacientes</option>
                <option v-for="(item, i) in patients"
                        :value="item.id" :key="i">{{ item.name }}</option>
              </select>
            </div>
            <div class="column form-group">
              <small>Tipo do atendimento</small>
              <select id="filter-service" class="form-select select-sm"
                      v-model="filter.serviceType">
                <option value="">Todos os tipos</option>
                <option v-for="(text, value) in serviceType.serviceTypes"
                        :value="value" :key="value">{{ text }}</option>
              </select>
            </div>
            <div class="column col-auto form-group">
              <small>Profissional</small>
              <div class="input-group">
                <select class="form-select select-sm bg-gray"
                        style="max-width: 120px"
                        v-model="filter.professionalType">
                  <option value="accredited">Credenciado</option>
                  <option value="executant">Executante</option>
                </select>
                <select id="filter-service" class="form-select select-sm"
                        v-model="filter.professionalId">
                  <option value="">Todos os profissionais</option>
                  <option value="not_accredited">Sem credenciado</option>
                  <option v-for="(item, i) in professionals"
                          :value="item.id" :key="i">{{ item.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="empty" v-if="filteredAccounts.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
          </div>
          <p class="empty-title h6">Contas da fatura</p>
          <p class="empty-subtitle">
            Nenhuma conta encontrada. Verifique os filtros acima!
          </p>
        </div>
        <template v-else>
          <div class="columns">
            <div class="column">
              <h6>Contas</h6>
            </div>
            <div class="column text-right text-bold" v-if="selectedAccounts > 0">
              <span>Contas selecionadas:</span>
              <strong class="text-info ml-1">{{ selectedAccounts }}</strong>
            </div>
            <div class="column col-auto text-right">
              <button class="btn btn-icon btn-icon-left btn-primary btn-sm mr-1"
                      v-if="allowCreateOrUpdate"
                      @click="createInvoice"
                      :disabled="!hasTransferAccounts">
                <fa-icon :icon="['fal', 'plus-circle']" />Criar nova fatura
              </button>
              <dx-dropdown
                class="form-group"
                direction="right"
                :items="availableTransfer"
                @select="transferAccounts"
                label="code">
                <button class="btn btn-icon btn-icon-left btn-primary btn-sm"
                        v-if="allowCreateOrUpdate"
                        @click="getAvailableTransfer"
                        :disabled="!hasTransferAccounts">
                  <fa-icon :icon="['fal', 'share']" />Transferir contas
                </button>
              </dx-dropdown>
            </div>
          </div>
          <div class="divider" />
          <table class="table table-hover table-striped">
            <thead>
            <tr>
              <th v-if="allowCreateOrUpdate">
                <label class="form-checkbox" style="padding-top: 0; margin-top: 0">
                  <input type="checkbox"
                         @change="selectAllAccounts"
                         :checked="getAccountsCheckStatus"
                         :indeterminate.prop="getAccountsCheckStatus === null">
                  <i class="form-icon"></i>
                </label>
              </th>
              <th style="width: 110px">
                Data / Conta
                <fa-icon class="ml-1 c-hand" :icon="['fal', 'sort']"
                         @click="filter.sort = 'startDate'" />
              </th>
              <th>
                Paciente / Carteirinha / Tipo
                <fa-icon class="ml-1 c-hand" :icon="['fal', 'sort']"
                         @click="filter.sort = 'patient'" />
              </th>
              <th>Prestador / Principal</th>
              <th>Operadora / Senha</th>
              <th class="text-right">
                Qtde. / Total<fa-icon class="ml-1 c-hand" :icon="['fal', 'sort']"
                                      @click="filter.sort = 'total'" />
              </th>
              <th class="text-right">Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in filteredAccounts" :key="item.id">
              <td style="width: 40px" v-if="allowCreateOrUpdate">
                <span class="tooltip tooltip-right" v-if="item.paid"
                      data-tooltip="Conta já repassada ao profissional">
                  <fa-icon class="text-warning" :icon="['fal', 'sack-dollar']"/>
                </span>
                <label class="form-checkbox" style="padding-top: 0; margin-top: 0" v-else>
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>
                <span>{{ item.startDate | date }}</span><br>
                <span>{{ item.code }}</span>
              </td>
              <td>
                <div>
                  <span class="hover-link" @click="edit(item.id, i)">
                    {{ item.patient.name }}
                  </span>
                  <small class="text-warning" v-if="item.courtesy">(Cortesia)</small>
                </div>
                <div v-if="item.insurance.insured && item.insurance.insured.record">
                  Carteirinha: {{ item.insurance.insured.record }}
                </div>
                <div>
                  <span v-if="item.referral">
                    {{ referralType.getName(item.referral.type) }}
                  </span>
                  <small v-if="item.serviceType">
                    <a class="text-info c-hand" @click="showExpenses(item.expenses)">
                      ({{ getServiceName(item.serviceType, item.consultationType) }})
                    </a>
                  </small>
                </div>
              </td>
              <td>
                <span>2 - Prestador: {{ item.referral.provider || '#' }}</span><br>
                <span>3 - Principal: {{ item.referral.main || '#' }}</span>
              </td>
              <td>
                <span>7 - Operadora: {{ item.referral.insurance || '#' }}</span><br>
                <span>
                  5- Senha: {{ item.authorization && item.authorization.password || '#' }}
                </span>
              </td>
              <td class="text-right">
                <span>x{{ item.expenses.length }}</span><br>
                {{ item.total | currency }}</td>
              <td class="text-right" style="min-width: 90px">
                <button class="btn btn-action btn-icon btn-sm btn-gray-outline mr-1"
                        :class="{loading: printing && idx === i}"
                        :disabled="printing || accountModal.loading"
                        @click="print(item.id, i)">
                  <fa-icon :icon="['fal', 'print']" />
                </button>
                <button class="btn btn-action btn-icon btn-sm btn-gray mr-1"
                        :class="{loading: accountModal.loading && idx === i}"
                        :disabled="accountModal.loading"
                        v-if="allowCreateOrUpdate"
                        @click="edit(item.id, i)">
                  <fa-icon :icon="['fal', 'pencil']" />
                </button>
                <button class="btn btn-action btn-icon btn-sm btn-error"
                        :class="{loading: deleting && idx === i}"
                        :disabled="deleting || item.paid || accountModal.loading"
                        v-if="allowCreateOrUpdate"
                        @click="remove(item.id, i)">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </template>
      <template slot="footer">
        <button class="btn" @click="close()">Sair</button>
      </template>
    </dx-modal>
    <referral-modal :show="accountModal.show"
                    :data="accountModal.data"
                    source="billing-invoices"
                    :type="accountModal.type"
                    v-if="accountModal.show"
                    @close="closeAccountModal"
    />
    <dx-modal title="Procedimentos" size="lg"
              v-model="expenseModal.show"
              id="account-expenses-modal">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>#</th>
          <th>Código</th>
          <th>Nome</th>
          <th>Profissional</th>
          <th class="text-center">Quantidade</th>
          <th class="text-right">Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in expenseModal.expenses" :key="i">
          <td>{{ i + 1}}</td>
          <td>{{ item.expense ? item.expense.code : item.code | tuss }}</td>
          <td>{{ item.expense ? item.expense.name : item.name  }}</td>
          <td>{{ item.professional ? item.professional.name : '-' }}</td>
          <td class="text-center">{{ item.quantity | number }}</td>
          <td class="text-right">{{ item.value.total | currency }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
    <invoice-payment-modal
      v-if="paymentModal.show"
      :show="paymentModal.show"
      :data="paymentModal.data"
      @close="paymentModal.show = false"
      @finished="finishedPaymentModal"
    />
  </div>
</template>

<script>
import { customName } from 'src/helpers/insurance';
import { getName as getStatusName } from 'src/data/invoice-statuses';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { parse as parseBoolean } from 'src/helpers/boolean';
import * as referralType from 'src/data/referral-types';
import * as serviceType from 'src/data/service-types';
import { date, minDate } from 'src/data/validators';
import { mergeFrom } from 'src/helpers/object';
import {
  CREATE_BILLING,
  UPDATE_BILLING,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  PRINT_ACCOUNT,
} from '@/data/actions/modules/financial';
import ReferralModal from 'src/app/appointment/components/modals/Referral.vue';
import { mapActions } from 'vuex';
import InvoicePaymentModal from './Payment.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    invoiceId: {
      type: String,
    },
  },
  components: {
    InvoicePaymentModal,
    ReferralModal,
  },
  data() {
    return {
      customName,
      getStatusName,
      referralType,
      serviceType,
      idx: null,
      loading: false,
      saving: false,
      printing: false,
      updating: false,
      deleting: false,
      professionals: [],
      patients: [],
      invoice: this.blankInvoice(),
      availableTransfer: [],
      filter: {
        startDate: '',
        endDate: '',
        referralType: '',
        serviceType: '',
        professionalType: 'executant',
        professionalId: '',
        patientId: '',
        accredited: '',
        courtesy: false,
        return: false,
        sort: 'startDate',
      },
      accountModal: {
        type: '',
        data: {},
        loading: false,
        show: false,
      },
      expenseModal: {
        expenses: [],
        show: false,
      },
      paymentModal: {
        show: false,
        data: {
          id: '',
          name: '',
          deliveryDate: '',
          invoiceId: '',
          invoiceCode: '',
          grossValue: '',
        },
      },
      statusBar: [
        {
          name: 'open',
          description: 'Em aberto',
          tooltip: 'Fatura vigente (lançamentos atuais)',
          color: '#66dcd8',
          selected: true,
        },
        {
          name: 'in_billing',
          description: 'Faturando',
          tooltip: 'Em processo de faturamento (revisão)',
          color: '#ffba7b',
          selected: false,
        },
        {
          name: 'closed',
          description: 'Fechada',
          tooltip: 'Não permite alterações (pronto para envio do XML)',
          color: '#818bd5',
          selected: false,
        },
        {
          name: 'in_appeal',
          description: 'Recurso',
          tooltip: 'Revisão de valores para recurso de glosa',
          color: '#ec91cb',
          selected: false,
        },
        {
          name: 'finished',
          description: 'Finalizada',
          tooltip: 'Fatura disponível para repasse',
          color: '#5684cc',
          selected: false,
        },
      ],
    };
  },
  mounted() {
    this.load();
    this.loadProfessionals();
  },
  computed: {
    selectedAccounts() {
      return this.invoice.accounts.filter(({ selected }) => selected).length;
    },
    filteredAccounts() {
      let { accounts } = this.invoice;

      if (this.filter.referralType) {
        accounts = accounts
          .filter(item => item.referral.type === this.filter.referralType);
      }

      if (this.filter.serviceType) {
        accounts = accounts
          .filter(item => item.serviceType === this.filter.serviceType);
      }

      if (!parseBoolean(this.filter.return)) {
        accounts = accounts
          .filter(item => item.consultationType !== 'return');
      }

      if (!parseBoolean(this.filter.courtesy)) {
        accounts = accounts
          .filter(item => item.courtesy === false);
      }

      if (this.filter.patientId) {
        accounts = accounts
          .filter(item => item.patient.id === this.filter.patientId);
      }

      if (this.filter.professionalId) {
        if (this.filter.professionalType === 'accredited') {
          if (this.filter.professionalId === 'not_accredited') {
            accounts = accounts
              .filter(item => !item.expenses.some(({ professional }) => professional.accredited));
          } else {
            accounts = accounts
              .filter((item) => {
                const professionalIds = item.expenses
                  .filter(({ professional }) => professional.accredited
                    && professional.accredited.id)
                  .map(({ professional }) => professional.accredited.id);
                return professionalIds.includes(this.filter.professionalId);
              });
          }
        } else {
          accounts = accounts
            .filter((item) => {
              const professionalIds = item.expenses
                .filter(({ professional }) => professional.id)
                .map(({ professional }) => professional.id);
              return professionalIds.includes(this.filter.professionalId);
            });
        }
      }

      if (this.filter.accredited !== '') {
        const accredited = parseBoolean(this.filter.accredited);
        accounts = accounts
          .filter(item => item.expenses
            .find(expense => expense.accredited === accredited));
      }

      if (!this.$v.filter.$error
        && this.filter.startDate
        && this.filter.endDate) {
        accounts = accounts
          .filter(item => moment(item.startDate)
            .isBetween(
              moment(this.filter.startDate),
              moment(this.filter.endDate),
              undefined,
              '[]',
            ));
      }

      accounts = accounts.sort((a, b) => {
        if (this.filter.sort === 'startDate') {
          if (a.startDate > b.startDate) return 1;
          if (a.startDate < b.startDate) return -1;
        } else if (this.filter.sort === 'total') {
          if (a.total > b.total) return 1;
          if (a.total < b.total) return -1;
        } else {
          if (a.patient.name > b.patient.name) return 1;
          if (a.patient.name < b.patient.name) return -1;
        }
        return 0;
      });

      return accounts;
    },
    canAccessCreateBilling() {
      if (CREATE_BILLING) {
        return this.$can(CREATE_BILLING);
      }
      return true;
    },
    canAccessUpdateBilling() {
      if (UPDATE_BILLING) {
        return this.$can(UPDATE_BILLING);
      }
      return true;
    },
    canAccessUpdateAccount() {
      if (UPDATE_ACCOUNT) {
        return this.$can(UPDATE_ACCOUNT);
      }
      return true;
    },
    canAccessPrintAccount() {
      if (PRINT_ACCOUNT) {
        return this.$can(PRINT_ACCOUNT);
      }
      return true;
    },
    canAccessRemoveAccount() {
      if (DELETE_ACCOUNT) {
        return this.$can(DELETE_ACCOUNT);
      }
      return true;
    },
    hasTransferAccounts() {
      return this.filteredAccounts.filter(({ paid }) => !paid).some(({ selected }) => selected);
    },
    getAccountsCheckStatus() {
      const accounts = this.filteredAccounts.filter(({ paid }) => !paid);

      if (!accounts || accounts.length === 0) {
        return false;
      }

      const accountsSelected = accounts.filter(account => account.selected);
      if (accountsSelected.length === 0) {
        return false;
      }
      if (accountsSelected.length === accounts.length) {
        return true;
      }
      return null;
    },
    isParticular() {
      return this.invoice.insurance.type === 'particular';
    },
    calcValues() {
      let particularValue = 0;
      let totalValue = 0;
      this.invoice.accounts
        .filter(account => !account.courtesy
          && (!account.consultationType || account.consultationType !== 'return'))
        .forEach((account) => {
          if (account.cashier && account.cashier.value) {
            particularValue += Number(account.cashier.value.total);
          }
          totalValue += Number(account.total);
        });
      return {
        particularValue,
        totalValue,
      };
    },
    particularValue() {
      const values = this.calcValues;
      return values.particularValue;
    },
    insuranceValue() {
      const value = this.totalValue - this.particularValue;
      return value >= 0 ? value : 0;
    },
    totalValue() {
      const values = this.calcValues;
      return values.totalValue;
    },
    allowCreateOrUpdate() {
      return this.invoice.status === 'in_billing' || this.invoice.status === 'open';
    },
    differentAccounts() {
      return this.filteredAccounts.length !== this.invoice.accounts.length;
    },
  },
  validations() {
    const roles = {
      filter: {
        startDate: { date },
        endDate: { date },
      },
    };

    if (this.filter.startDate || this.filter.endDate) {
      roles.filter.startDate = { required, date };
      roles.filter.endDate = { required, date };
      roles.filter.endDate.minDate = minDate(moment(this.filter.startDate)
        .format('YYYY-MM-DD'));
    }

    return roles;
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    showExpenses(expenses) {
      this.expenseModal.expenses = expenses;
      this.expenseModal.show = true;
    },
    setStatus(status) {
      if (!this.canAccessUpdateBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (this.invoice.status === status) {
        return;
      }

      if (this.updating) return;

      if (status === 'finished' && this.insuranceValue > 0) {
        this.openPaymentModal();
      } else {
        this.saveStatus(status);
      }
    },
    saveStatus(status) {
      this.updating = true;

      const data = {
        status,
      };

      return this.$http
        .put(`/billing-invoices/${this.invoice.id}`, data)
        .then(() => {
          this.setStatusColor(status);
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error' });
          }
        })
        .finally(() => {
          this.updating = false;
        });
    },
    setStatusColor(status) {
      if (this.invoice.status === 'open' && status !== 'open') {
        this.$toast.show(`Atenção. Uma nova fatura será criada para os
          novos agendamentos deste convênio!`, { timeout: 5000 });
      }
      this.invoice.status = status;
      const found = this.statusBar.find(({ name }) => name === status);
      const idx = this.statusBar.indexOf(found);
      this.statusBar.forEach((item, i) => {
        item.selected = (i <= idx);
      });
    },
    load() {
      this.loading = true;
      return this.$http.get(`/billing-invoices/${this.invoiceId}`)
        .then(({ data }) => {
          data.accounts.forEach((account) => {
            const foundPatient = this.patients.find(({ id }) => id === account.patient.id);
            if (!foundPatient) {
              this.patients.push({
                id: account.patient.id,
                name: account.patient.name,
              });
            }
            account.paid = account.expenses.some(({ financialBundleId }) => financialBundleId);
            account.selected = false;
            return account;
          });
          this.patients.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          this.invoice = mergeFrom(this.blankInvoice(), data);
          this.setStatusColor(this.invoice.status);
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.professionals = items;
        })
        .catch(() => {});
    },
    edit(accountId, i) {
      if (this.accountModal.loading) {
        return null;
      }

      if (!this.canAccessUpdateAccount) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return null;
      }

      this.idx = i;
      this.accountModal.loading = true;

      let account;
      return this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          data.invoiceId = this.invoice.id;
          account = data;
          this.accountModal.type = account.referral.type || account.patient.insurance.type;

          this.addExpenseRules(account)
            .finally(() => {
              this.accountModal.data = account;
              this.accountModal.show = true;
            });
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.accountModal.loading = false;
        });
    },
    print(accountId, i) {
      if (!this.canAccessPrintAccount) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }

      this.printing = true;
      this.idx = i;

      return this.$file
        .print(`/billing-accounts/${accountId}/print`)
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
    remove(accountId, i) {
      if (!this.canAccessRemoveAccount) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir esta conta?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.idx = i;
              close();
              this.$http
                .delete(`/billing-accounts/${accountId}`)
                .then(() => {
                  const found = this.invoice.accounts.find(({ id }) => id === accountId);
                  this.invoice.accounts.splice(this.invoice.accounts.indexOf(found), 1);
                  if (this.invoice.accounts.length === 0) {
                    this.close();
                  }
                })
                .catch((e) => {
                  if (e.response
                    && e.response.data
                    && e.response.data.message) {
                    this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
                  } else {
                    this.$toast.error('Ocorreu um erro. Tente novamente!');
                  }
                })
                .finally(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    close() {
      this.invoice.quantity = this.invoice.accounts.length;
      this.$emit('close', this.invoice);
    },
    closeAccountModal(account) {
      if (account) {
        const foundAccount = this.invoice.accounts.find(({ id }) => id === account.id);
        foundAccount.startDate = account.startDate;
        foundAccount.endDate = account.endDate;
        foundAccount.referral.type = account.referral.type;
        foundAccount.referral.provider = account.referral.provider;
        foundAccount.referral.insurance = account.referral.insurance;
        foundAccount.referral.main = account.referral.main;
        foundAccount.serviceType = account.serviceType;
        if (account.authorization && account.authorization.password) {
          foundAccount.authorization.password = account.authorization.password;
        }
        foundAccount.expenses = account.expenses;
        foundAccount.total = account.expenses.reduce((a, b) => a + b.value.total, 0);
      }
      this.accountModal.show = false;
    },
    addExpenseRules(account) {
      const planId = account.patient.insurance.plan.id;

      const params = {
        expenseIds: account.expenses.map(({ id }) => id).join(','),
      };
      return this.$http.get(`/expense-insurances/${planId}/rules`, { params })
        .then(({ data }) => {
          if (data.items) {
            if (data.items.length > 0) {
              data.items.forEach((item) => {
                account.expenses.forEach((expense) => {
                  if (item.id === expense.id) {
                    if (item.rules) {
                      expense.rules = item.rules;
                    }
                  }
                });
              });
            }
          }
        });
    },
    selectAllAccounts(e) {
      this.filteredAccounts.filter(({ paid }) => !paid).forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    async getAvailableTransfer() {
      if (!(this.invoice.status === 'open' || this.invoice.status === 'in_billing')) {
        this.$toast.error('Disponível apenas para status: Aberto ou Faturando!');
        return;
      }

      const accountIds = this.invoice.accounts
        .filter(({ selected }) => selected)
        .map(item => item.id);

      if (accountIds.length === 0) {
        this.$toast.error('Selecione ao menos uma conta!');
        return;
      }

      const params = {
        insurancePlanId: this.invoice.insurance.plan.id,
      };

      await this.$http
        .get('/billing-invoice-codes', { params })
        .then(({ data }) => {
          if (data.items && data.items.length > 0) {
            this.availableTransfer = data.items
              .filter(invoice => invoice.id !== this.invoice.id)
              .map((invoice) => {
                const detail = invoice.status === 'open' ? '(Vigente)' : '';
                invoice.code = `${invoice.code} ${detail}`;
                return invoice;
              });
          }
        });
      if (this.availableTransfer.length === 0) {
        this.availableTransfer.push({
          id: 0,
          code: 'Nenhuma fatura disponível',
        });
      }
    },
    transferAccounts(invoice) {
      if (!this.canAccessUpdateBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (invoice.id === 0) {
        return;
      }

      const accountIds = this.invoice.accounts
        .filter(({ selected }) => selected)
        .map(item => item.id);

      if (accountIds.length === 0) {
        this.$toast.show('Selecione ao menos uma conta!', { type: 'error' });
        return;
      }

      const yesAction = (close) => {
        if (this.saving) return;

        this.saving = true;

        close();

        const params = {
          accountIds,
          invoiceId: invoice.id,
        };

        this.$http
          .put(`/billing-invoices/${this.invoice.id}/transferAccounts`, params)
          .then(() => {
            accountIds.forEach((accountId) => {
              this.removeAccountFromInvoice(accountId);
            });
            if (this.invoice.accounts.length === 0) {
              this.close();
            }
            this.$toast.show('Contas transferidas com sucesso!');
          })
          .catch(() => {
            this.$toast.error('Ocorreu um erro. Tente novamente!');
          })
          .finally(() => {
            this.saving = false;
          });
      };

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente transferir as contas para outra fatura?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$nextTick(() => {
                yesAction(close);
              });
            },
          },
        ],
      });
    },
    createInvoice() {
      if (!this.canAccessCreateBilling) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      const accountIds = [];

      this.invoice.accounts.forEach((item) => {
        if (item.selected) {
          accountIds.push(item.id);
        }
      });

      if (accountIds.length === 0) {
        this.$toast.show('Selecione ao menos uma conta!', { type: 'error' });
        return;
      }

      const yesAction = (close) => {
        if (this.loading || this.deleting) return;
        this.loading = true;

        close();

        this.$http
          .put(`/billing-invoices/${this.invoice.id}/bindAccounts`, accountIds)
          .then(() => {
            accountIds.forEach((accountId) => {
              this.removeAccountFromInvoice(accountId);
            });
            if (this.invoice.accounts.length === 0) {
              this.close();
            }
            this.$toast.show('Fatura criada com sucesso');
          })
          .catch()
          .then(() => {
            this.loading = false;
          });
      };

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente criar uma nova fatura com as contas selecionadas?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$nextTick(() => {
                yesAction(close);
              });
            },
          },
        ],
      });
    },
    removeAccountFromInvoice(accountId) {
      const found = this.invoice.accounts
        .find(({ id }) => id === accountId);
      this.invoice.accounts.splice(this.invoice.accounts.indexOf(found), 1);
    },
    getServiceName(service, consultation) {
      if (!service && !consultation) {
        return 'Não informado';
      }
      if (consultation) {
        switch (consultation) {
          case 'first':
            return 'Primeira consulta';
          case 'return':
            return 'Consulta de retorno';
          case 'forwarding':
            return 'Consulta por encaminhamento';
          default:
            return 'Consulta de pré-natal';
        }
      }
      return serviceType.getName(service);
    },
    openPaymentModal() {
      this.paymentModal.data = {
        id: this.invoice.insurance.plan.id,
        name: customName(this.invoice.insurance),
        deliveryDate: this.invoice.deliveryDate,
        invoiceId: this.invoice.id,
        invoiceCode: this.invoice.code,
        grossValue: this.insuranceValue,
      };
      this.paymentModal.show = true;
    },
    finishedPaymentModal() {
      this.paymentModal.show = false;
      this.invoice.status = 'finished';
      this.$emit('close', this.invoice);
    },
    blankInvoice() {
      return {
        id: '',
        code: '',
        status: '',
        protocol: '',
        lot: '',
        deliveryDate: '',
        receiptDate: '',
        paymentDate: '',
        quantity: '',
        insurance: {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
          imageUrl: '',
        },
        value: {
          appealed: '',
          recovered: '',
          total: '',
        },
        accounts: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import '../../../../../assets/scss/variables';
#billing-invoice-detail-modal {
  .info-group {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    padding: $layout-spacing;
  }
  .filter-group {
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    padding: $layout-spacing;
  }
  .menu .menu-item > a:hover {
    background-color: $gray-color-light;
    color: $dark-color;
  }

  .group-status {
    margin: $layout-spacing 0;
  }

  .status-default {
    background-color: $gray-color-light;
    color: $gray-color-dark;
  }

  .status-open {
    background-color: #36d6d0;
    font-weight: bold;
  }

  .status-in_billing {
    background-color: #ffba7b;
    font-weight: bold;
  }

  .status-closed {
    background-color: #817fe3;
    font-weight: bold;
  }

  .status-in_appeal {
    background-color: #f39cdc;
    font-weight: bold;
  }

  .status-finished {
    background-color: #5c8fdc;
    font-weight: bold;
  }
}

#financial-modal {
  .transaction-tiles {
    display: flex;
    padding: 0 0 $layout-spacing 0;
    color: #fff;
    .tile-item {
      background-color: $secondary-color;
      border-radius: $border-radius;
      flex: 1;
      margin-left: $layout-spacing;
      padding: 10px;
      position: relative;
      text-align: center;
      &:first-child {
        margin-left: 0;
      }
    }
    .tile-label {
      font-size: $font-size-sm;
      line-height: $font-size-sm;
      margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
    }
    .tile-value {
      font-size: 14px;
      font-weight: 600;
      line-height: $font-size;
      margin-top: $layout-spacing;
    }
  }
}
</style>
