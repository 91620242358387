export const statuses = {
  open: 'Em aberto',
  in_billing: 'Faturando',
  closed: 'Fechada',
  in_appeal: 'Recurso',
  finished: 'Finalizada',
  canceled: 'Cancelada',
};

export function getName(value) {
  return statuses[value];
}
