<template>
  <dx-modal
    v-model="show"
    title="Conta do paciente"
    id="branch-account-modal-page"
    size="xl">
    <account-data :id="id" />
  </dx-modal>
</template>

<script>
import accountData from './Data.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  components: {
    accountData,
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";
.account-modal-page {}
</style>
