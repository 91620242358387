<template>
  <dx-modal title="Recebimento de fatura" size="xl"
            @input="close"  :value="show">
    <div class="columns form-group">
      <div class="column col-9 form-group">
        <label class="form-label">Favorecido</label>
        <input type="text" class="form-input text-bold"
               :value="data.name" readonly>
      </div>
      <div class="column col-3 form-group">
        <label class="form-label">Documento</label>
        <input type="text" class="form-input text-bold"
               :value="data.invoiceCode" readonly>
      </div>
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.form.categoryId.$error}">
        <label class="form-label">Categoria</label>
        <dx-autocomplete
          id="category"
          v-model="category"
          :readonly="!!form.categoryId"
          :source="findCategory"
          label="name"
          track-by="id"
          @select="setCategory"
          :debounce="800"
          placeholder="Informe o nome ou pesquise no botão ao lado..."
        >
          <button slot="action" v-if="category" @click="unsetCategory"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="'btn-gray'"
                  tabindex="-1">
            <fa-icon :icon="['fal', 'times']" />
          </button>
          <button slot="action" v-else
                  @click="openCategoryCostCenterModal('category')"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="'btn-neutral'"
                  tabindex="-1">
            <fa-icon :icon="['fal', 'search']" />
          </button>
          <template v-slot="{ item }">
            <div v-if="item.id === null">
              Categoria não encontrada!
            </div>
            <div v-else>
              <span v-if="item.parent">{{ item.parent.name }} > </span>
              <strong>{{ item.name }}</strong>
            </div>
          </template>
        </dx-autocomplete>
        <template v-if="$v.form.categoryId.$error">
          <div class="form-input-hint"
               v-if="!$v.form.categoryId.required">Campo obrigatório</div>
        </template>
      </div>
      <div class="column col-6 col-sm-12 form-group">
        <label class="form-label">Centro de custo</label>
        <dx-autocomplete
          id="category"
          v-model="costCenter"
          :readonly="!!form.costCenterId"
          :source="findCostCenter"
          label="name"
          track-by="id"
          @select="setCostCenter"
          :debounce="800"
          placeholder="Informe o nome ou pesquise no botão ao lado..."
        >
          <button slot="action" v-if="costCenter" @click="unsetCostCenter"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="'btn-gray'"
                  tabindex="-1">
            <fa-icon :icon="['fal', 'times']" />
          </button>
          <button slot="action" v-else
                  @click="openCategoryCostCenterModal('costCenter')"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="'btn-neutral'"
                  tabindex="-1">
            <fa-icon :icon="['fal', 'search']" />
          </button>
          <template v-slot="{ item }">
            <div v-if="item.id === null">
              Centro de custo não encontrado!
            </div>
            <div v-else>
              <span v-if="item.parent">{{ item.parent.name }} > </span>
              <strong>{{ item.name }}</strong>
            </div>
          </template>
        </dx-autocomplete>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Observações</label>
        <textarea id="notes" class="form-input" rows="2"
                  v-model="form.notes"></textarea>
      </div>
      <div class="column col-3 col-md-12 col-sm-12 form-group">
        <label class="form-label">Valor bruto</label>
        <dx-input-number id="current-value"
                         v-model="form.value"
                         class="form-input text-right text-bold"
                         :precision="2"
                         readonly
        />
      </div>
      <div class="column col-3 col-md-12 col-sm-12 form-group">
        <label class="form-label">Acréscimo</label>
        <dx-input-number id="current-value"
                         v-model="form.interest"
                         class="form-input text-right"
                         maxlength="8"
                         :disabled="definition.installment > 1"
                         :precision="2"
        />
      </div>
      <div class="column col-3 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.form.discount.$error}">
        <label class="form-label">Desconto</label>
        <dx-input-number id="current-value"
                         v-model="form.discount"
                         maxlength="8"
                         @change="$v.form.discount.$touch()"
                         class="form-input text-right"
                         :disabled="definition.installment > 1"
                         :precision="2"
        />
      </div>
      <div class="column col-3 col-md-12 col-sm-12 form-group">
        <label class="form-label">Valor total a receber</label>
        <dx-input-number id="current-value"
                         v-model="total"
                         class="form-input text-right text-bold"
                         :precision="2"
                         readonly
        />
      </div>
    </div>
    <strong>Condição de recebimento</strong>
    <div class="divider" />
    <div class="columns">
      <div class="column col-2 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.definition.issueDate.$error}">
        <label class="form-label">Data emissão</label>
        <dx-input-date
          id="due-date"
          class="form-input"
          :disabled="definition.installment > 1"
          @change="addIssueDate"
          v-model="definition.issueDate"
          @blur="$v.definition.issueDate.$touch()"
        />
        <template v-if="$v.definition.issueDate.$error">
          <div class="form-input-hint"
               v-if="!$v.definition.issueDate.date">Data inválida</div>
        </template>
      </div>
      <div class="column col-2 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.definition.dueDate.$error}">
        <label class="form-label">
          {{ definition.installment > 1 ? '1º Vencimento' : 'Data de vencimento' }}
        </label>
        <dx-input-date
          id="due-date"
          class="form-input"
          v-model="definition.dueDate"
          @change="addInstallments"
          @blur="$v.definition.dueDate.$touch()"
        />
        <template v-if="$v.definition.dueDate.$error">
          <div class="form-input-hint"
               v-if="!$v.definition.dueDate.date">Data inválida</div>
        </template>
      </div>
      <div class="column col-2 form-group">
        <label class="form-label">Parcelamento</label>
        <select id="method" class="form-select"
                v-model="definition.installment"
                :disabled="hasTotalError">
          <option value="">À vista</option>
          <option v-for="value in installments" :value="value"
                  :key="value">{{ value }}x</option>
        </select>
      </div>
      <div class="column col-3 col-sm-12 form-group">
        <label class="form-label">Frequência</label>
        <select id="recurrent-repetition" class="form-select"
                v-model="definition.repetition"
                :disabled="definition.installment <= 1"
                @change="addInstallments">
          <option value="days">Dia(s)</option>
          <option value="weeks">Semana(s)</option>
          <option value="months">Mês(es)</option>
          <option value="years">Ano(s)</option>
        </select>
      </div>
      <div class="column col-1 form-group">
        <label class="form-label">Baixado?</label>
        <select id="bank-account" class="form-select"
                :disabled="definition.installments.length > 1"
                v-model="definition.paid">
          <option :value="true">Sim</option>
          <option :value="false">Não</option>
        </select>
      </div>
      <div class="column col-2 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.definition.paymentDate.$error}">
        <label class="form-label">Data pagamento</label>
        <dx-input-date
          id="due-date"
          class="form-input"
          :disabled="!definition.paid"
          v-model="definition.paymentDate"
          @blur="$v.definition.paymentDate.$touch()"
        />
        <template v-if="$v.definition.paymentDate.$error">
          <div class="form-input-hint"
               v-if="!$v.definition.paymentDate.date">Data inválida</div>
        </template>
      </div>
      <div class="column col-sm-12 form-group"
           :class="{'has-error': $v.definition.methodId.$error}">
        <label class="form-label">Forma de cobrança</label>
        <select id="method" v-model="definition.methodId" class="form-select"
                @change="addInstallments">
          <option value="">[Selecione uma forma]</option>
          <option v-for="item in methods" :value="item.id"
                  :key="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-sm-12 form-group"
           :class="{'has-error': $v.definition.bankAccountId.$error}">
        <label class="form-label">Conta bancária</label>
        <select id="bank-account" class="form-select"
                v-model="definition.bankAccountId"
                @change="addInstallments">
          <option value="">[Selecione uma conta]</option>
          <option v-for="item in bankAccounts" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <table class="table table-hover" v-if="definition.installment > 1">
      <thead>
      <tr>
        <th class="text-center" style="width:30px">#</th>
        <th style="width:130px">Vencimento</th>
        <th class="text-right" style="width:150px">Valor (R$)</th>
        <th>Forma de cobrança</th>
        <th>Conta bancária</th>
        <th>Baixado?</th>
        <th style="width:130px">Pagamento</th>
      </tr>
      </thead>
      <tbody>
      <table-row
        ref="tableRow"
        v-for="(item, i) in definition.installments" :key="i"
        :idx="i"
        :data="item"
        :bankAccounts="bankAccounts"
        :methods="methods"
      />
      </tbody>
    </table>
    <modal-category-and-cost-center
      v-if="modalCategoryCostCenter.show"
      :show="modalCategoryCostCenter.show"
      :type="modalCategoryCostCenter.type"
      :document-type="form.type"
      @selected="selectedCategoryCostCenter"
      @close="modalCategoryCostCenter.show = false"
    />
    <template slot="footer">
      <button class="btn btn-primary btn-icon-left mr-1"
              :class="{loading: saving}"
              :disabled="saving || hasTotalError"
              @click="save">
        <fa-icon :icon="['fal', 'sack-dollar']"/>Lançar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import moment from 'moment';
import { required, maxValue } from 'vuelidate/src/validators';
import localforage from 'localforage';
import formMixin from '../../../../../mixins/form';
import {
  date,
  minDate,
} from '../../../../../data/validators';
import types from '../../../../../data/financial-types';
import TableRow from './Row.vue';
import ModalCategoryAndCostCenter from '../../../transaction/components/modals/CategoryAndCostCenter.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    data: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      deliveryDate: {
        type: String,
      },
      invoiceId: {
        type: String,
      },
      invoiceCode: {
        type: String,
      },
      grossValue: {
        type: Number,
      },
    },
  },
  components: {
    ModalCategoryAndCostCenter,
    TableRow,
  },
  mixins: [formMixin],
  data() {
    return {
      types,
      category: null,
      costCenter: null,
      bankAccounts: [],
      methods: [],
      installments: [],
      definition: {
        issueDate: this.data.deliveryDate || moment().format('YYYY-MM-DD'),
        dueDate: moment().format('YYYY-MM-DD'),
        paymentDate: moment().format('YYYY-MM-DD'),
        methodId: '',
        bankAccountId: '',
        installment: '',
        paid: true,
        interval: 1,
        repetition: 'months',
        installments: [],
      },
      modalCategoryCostCenter: {
        show: false,
        type: 'category',
      },
      form: this.blankForm(),
      loading: true,
      saving: false,
    };
  },
  async mounted() {
    this.load();
    this.loadMethods();
    this.loadInstallments();
    this.loadBankAccounts();
    await this.loadFilters();
  },
  watch: {
    'definition.installment': function installment() {
      if (this.definition.installment > 1) {
        this.definition.paid = false;
      }
      this.addDueDate();
      this.addInstallments();
    },
    'definition.paid': function paid() {
      this.definition.paymentDate = this.definition.paid
        ? moment().format('YYYY-MM-DD') : '';
    },
  },
  computed: {
    total() {
      if (this.hasTotalError) {
        return 0;
      }
      return this.data.grossValue - this.form.discount + this.form.interest;
    },
    hasTotalError() {
      return this.$v.form.discount.$error;
    },
  },
  validations() {
    const rules = {
      form: {
        type: { required },
        entityId: { required },
        categoryId: { required },
        discount: { maxValue: maxValue(this.data.grossValue + this.form.interest - 0.01) },
      },
      definition: {
        issueDate: { required, date },
        dueDate: {
          required,
          date,
          minDate: minDate(this.definition.issueDate),
        },
        paymentDate: {},
        methodId: {},
        bankAccountId: {},
        repetition: {},
      },
    };

    if (this.definition.installment <= 1) {
      rules.definition.methodId = { required };
      rules.definition.bankAccountId = { required };
      rules.definition.repetition = { required };
    }

    if (this.definition.paid) {
      rules.definition.paymentDate = {
        required,
        date,
        minDate: minDate(this.definition.issueDate),
      };
    }

    return rules;
  },
  methods: {
    load() {
      this.form.entityId = this.data.id;
      this.form.value = Math.abs(this.data.grossValue);
    },
    async loadFilters() {
      return localforage.getItem('invoicePaymentFilters')
        .then((filters) => {
          if (filters) {
            if (filters.categoryId) {
              this.getCategory(filters.categoryId);
            }
            if (filters.costCenterId) {
              this.getCostCenter(filters.costCenterId);
            }
            if (filters.bankAccountId) {
              const found = this.bankAccounts.find(item => item.id === filters.bankAccountId);
              if (found) {
                this.definition.bankAccountId = found.id;
              }
            }
            if (filters.methodId) {
              const found = this.methods.find(item => item.id === filters.methodId);
              if (found) {
                this.definition.methodId = found.id;
              }
            }
          }
        })
        .catch(() => {});
    },
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
      };

      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          this.bankAccounts = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
      };

      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.methods = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInstallments() {
      this.installments = [];
      for (let i = 1; i <= 12; i += 1) {
        this.installments.push(i);
      }
    },
    findCategory(search) {
      const params = {
        active: true,
        allowsValue: true,
        type: this.form.type,
        limit: 0,
        search,
      };

      return this.$http.get('/categories', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setCategory(data) {
      this.category = null;
      this.form.categoryId = '';
      if (data && data.id) {
        this.category = {
          id: data.id,
          name: data.name,
        };
        this.form.categoryId = data.id;
      }
    },
    unsetCategory() {
      this.category = null;
      this.setCategory(null);
    },
    getCategory(id) {
      return this.$http.get(`/categories/${id}`)
        .then(({ data }) => {
          if (data && data.type === 'revenue') {
            this.form.categoryId = id;
            this.category = {
              id: data.id,
              name: data.name,
            };
          }
        });
    },
    findCostCenter(search) {
      const params = {
        active: true,
        allowsValue: true,
        limit: 0,
        search,
      };

      return this.$http.get('/cost-centers', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setCostCenter(data) {
      this.costCenter = null;
      this.form.costCenterId = '';
      if (data && data.id) {
        this.costCenter = {
          id: data.id,
          name: data.name,
        };
        this.form.costCenterId = data.id;
      }
    },
    unsetCostCenter() {
      this.costCenter = null;
      this.setCostCenter(null);
    },
    getCostCenter(id) {
      return this.$http.get(`/cost-centers/${id}`)
        .then(({ data }) => {
          if (data) {
            this.form.costCenterId = id;
            this.costCenter = {
              id: data.id,
              name: data.name,
            };
          }
        });
    },
    openCategoryCostCenterModal(type) {
      this.modalCategoryCostCenter.type = type;
      this.modalCategoryCostCenter.show = true;
    },
    selectedCategoryCostCenter(item) {
      if (this.modalCategoryCostCenter.type === 'category') {
        this.form.categoryId = item.id;
        this.category = {
          id: item.id,
          name: item.name,
        };
      } else {
        this.form.costCenterId = item.id;
        this.costCenter = {
          id: item.id,
          name: item.name,
        };
      }
      this.modalCategoryCostCenter.show = false;
    },
    clearDefinition() {
      this.definition.methodId = '';
      this.definition.bankAccountId = '';
      this.definition.interval = 1;
      this.definition.repetition = 'months';
      this.definition.installments = [];
      if (this.paymentType === 'repeat') {
        this.definition.installment = 1;
      } else {
        this.definition.installment = '';
      }
    },
    addInstallments() {
      if (!this.definition.dueDate) {
        this.addDueDate();
      }
      if (!this.definition.interval) {
        this.definition.interval = 1;
      }

      let { dueDate } = this.definition;
      const value = Number((this.total / this.definition.installment).toFixed(2));

      let totalValue = 0;
      this.definition.installments = [];
      for (let i = 0; i < this.definition.installment; i += 1) {
        this.definition.installments.push({
          id: null,
          issueDate: this.definition.issueDate,
          dueDate,
          paymentDate: i === 0 ? dueDate : '',
          value,
          methodId: this.definition.methodId,
          bankAccountId: this.definition.bankAccountId,
          paid: i === 0,
        });
        totalValue += value;
        dueDate = moment(dueDate)
          .add(this.definition.interval, this.definition.repetition)
          .format('YYYY-MM-DD');
      }

      if (this.definition.installments.length > 0) {
        this.definition.installments[0].value += this.total - totalValue;
      }
    },
    addIssueDate() {
      if (this.definition.issueDate === '') {
        this.definition.issueDate = moment().format('YYYY-MM-DD');
      }
    },
    addDueDate() {
      if (this.definition.installment === '') {
        this.definition.dueDate = moment().format('YYYY-MM-DD');
      } else {
        this.definition.dueDate = moment().add(1, 'months').format('YYYY-MM-DD');
      }
    },
    async save() {
      this.$v.form.$touch();
      this.$v.definition.$touch();
      if (this.$v.form.$error || this.$v.definition.$error) {
        return null;
      }

      let tableRowValid = true;
      if (this.definition.installment > 1) {
        for (let i = 0; i < this.definition.installments.length; i += 1) {
          tableRowValid = this.$refs.tableRow[i].validate();
        }
      }

      if (!tableRowValid) {
        return null;
      }

      await localforage.setItem('invoicePaymentFilters', {
        categoryId: this.form.categoryId,
        costCenterId: this.form.costCenterId,
        methodId: this.definition.methodId,
        bankAccountId: this.definition.bankAccountId,
      });

      this.saving = true;

      const data = this.clone(this.form);
      delete data.value;
      data.issueDate = this.definition.issueDate;
      data.total = this.total;
      data.discount = this.form.discount;
      data.interest = this.form.interest;
      data.invoiceIds = [this.data.invoiceId];

      if (this.definition.installment > 1) {
        data.installments = this.definition.installments;
      } else {
        data.installments.push({
          dueDate: this.definition.dueDate,
          paymentDate: this.definition.paymentDate,
          value: this.total,
          methodId: this.definition.methodId,
          bankAccountId: this.definition.bankAccountId,
          paid: this.definition.paid,
        });
      }

      return this.$http.put(`/billing-invoices/${this.data.invoiceId}/finalize`, data)
        .then(() => {
          this.finished();
          this.$toast.show('Fatura baixada com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    close() {
      this.$emit('close');
    },
    finished() {
      this.$emit('finished');
    },
    blankForm() {
      return {
        type: 'revenue',
        entityId: '',
        categoryId: '',
        costCenterId: '',
        value: '',
        interest: 0,
        discount: 0,
        notes: '',
        installments: [],
      };
    },
  },
};
</script>

<style lang="scss">
</style>
